import { Component, input, output, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceInfosResolver } from 'src/app/resolvers';
import { LiteralService } from 'src/app/services';
import { initialServiceInfoDetailsState } from 'src/app/store/initialisation/service-infos';
import { images } from 'src/images';

@Component({
    selector: 'app-service-modal',
    templateUrl: './service-modal.component.html',
    styleUrl: './service-modal.component.scss',
    standalone: false
})
export class ServiceModalComponent {

  readonly serviceInfos = signal(initialServiceInfoDetailsState);
  readonly serviceInfoSelected = input<any>();
  selectServiceInfo = output<any>();
  openGTFSModal = output();
  openExportGTFSModal = output();
  openImportCSVModal = output();
  showRoutes = output<any>();
  addService = output();

  public searching = false;
  public showGTFsOptions = false;

  public images = images;

  constructor(public literalService: LiteralService, private activatedRoute: ActivatedRoute, private serviceInfosResolver: ServiceInfosResolver) {
  }

  showGTFs(event: any) {
    event.stopPropagation();
    this.showGTFsOptions = !this.showGTFsOptions;
    const gtfsIcon = document.getElementById('gtfsImportIcon')?.getBoundingClientRect();
    if (gtfsIcon) {
      document.getElementById('gtfsOptions')!.style.top =
        (gtfsIcon.top - 70).toString() + 'px';
      document.getElementById('gtfsOptions')!.style.left =
        (gtfsIcon.left - 120).toString() + 'px';
    }
  }

  async changePageService(event: { isNext: boolean, specificPage?: number }) {
      this.searching = true;
      this.serviceInfos.set(initialServiceInfoDetailsState);
      const serviceInfos = this.serviceInfos();
      // serviceInfos.value = [];
      if (!event.specificPage) {
        serviceInfos.pagination.actualPage = event.isNext
          ? serviceInfos.pagination.actualPage + 1
          : serviceInfos.pagination.actualPage - 1;
      } else {
        serviceInfos.pagination.actualPage = event.specificPage;
      }
      await this.getServiceInfos();
      this.searching = false;
  }

  async getServiceInfos() {
    const serviceInfos = this.serviceInfos();
    serviceInfos.pagination.numberOfElements = 6;
    this.activatedRoute.snapshot.data['services'] = serviceInfos;
    await this.serviceInfosResolver.resolve(this.activatedRoute.snapshot);
  }

  public async filter(event: any) {
    // this.searching = true;
    // this.services = initialServicesState;
    // this.services.value = [];
    // this.services.pagination.actualPage = 0;
    // await this.getServices();
    // this.searching = false;
  }

  public async search(event: any) {
    // this.searching = true;
    // this.services = initialServicesState;
    // this.services.value = [];
    // // this.searchValue = event;
    // this.services.pagination.actualPage = 0;
    // await this.getServices();
    // this.searching = false;
  }

  async getServices() {
    // this.services.pagination.numberOfElements = 10;
    // this.activatedRoute.snapshot.data['services'] = this.services;
    // await this.servicesResolver.getBookings(
    //   this.activatedRoute.snapshot
    // );
  }

  // addService() {

  // }

  // selectServiceInfo(serviceInfo: IServiceInfo) {
  //   // console.log(serviceInfo);
  //   this.serviceInfoSelected = serviceInfo;
  //   selectService
  //   // console.log(this.serviceInfoSelected);
  // }

}
