<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-96 max-h-full rounded-lg max-w-[343px]">
        <form #form novalidate (ngSubmit)="onSubmit(reason)">
            <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700">
                @if (!deleting) {
                    <div class="flex flex-col items-center px-6 pt-6 gap-6">
                        <div class="flex flex-row justify-between items-center w-full">
                            <h3 class="w-full text-xl font-bold">{{ literalService.get(title, true) }}</h3>
                            <button (click)="modal.hide(); reason = ''" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <img [src]="images.close" class="h-6 w-6" />
                            </button>
                        </div>
                        <p class="w-full whitespace-pre-line text-base">{{ literalService.get(text, true) }}</p>
                    </div>
                    <div class="px-6 pt-6">
                        <div class="flex gap-2 mb-2">
                            <img [src]="images.comment" alt="comment" class="h-6 w-6 mt-1" />
                            <label class="font-normal text-base">{{ literalService.get('comment', true) }}</label>
                        </div>
                        <textarea [(ngModel)]="reason" (change)="change(reason)" (input)="onChange()" [ngModelOptions]="{standalone: true}" [placeholder]="literalService.get('maxChar150Optional', true)" class="block w-full h-32 px-3 py-2 bg-white border rounded-s rounded-e text-base font-normal placeholder-slate-400 focus:outline-none"></textarea>
                        <label class="absolute text-xs hasErrors mt-[2px]" *ngIf="hasError">{{ error }}</label>
                        <label class="text-[10px] absolute right-6 mt-[2px] text-slate-400" [ngClass]="{'hasErrors': chars > maxLength}">{{ chars + '/' + maxLength}}</label>
                    </div>
                }
                @if (deleting) {
                    <article class="flex w-full pb-6 pt-4 ml-10 pl-7 pr-7 justify-center items-center">
                        <ng-lottie width="75%" height="75%" [options]="optionsBus" (animationCreated)="utilsService.animationCreated($event, 1.5)" />
                    </article>
                }
                @if (!deleting) {
                    <div class="flex justify-center items-center px-6 pb-6 pt-14">
                        <button type="button" class="secondary mr-2 gap-4 border-2 font-semibold h-10 w-full uppercase cursor-pointer" (click)="close()">{{ literalService.get('close', true) }}</button>
                        <button type="submit" class="primary gap-4 border-2 font-semibold h-10 w-full uppercase cursor-pointer text-white">{{ literalService.get('confirm', true) }}</button>
                    </div>
                }
                @if (deleting) {
                    <div class="flex items-center justify-center">
                        <h3 class="text-xl font-bold mb-4 -mt-4 text-gray-500 dark:text-gray-400 primary-purple-light-text">{{ literalService.get(id, true)}}</h3>
                    </div>
                }
            </div>
        </form>
    </div>
</div>