import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IBase, IBookingCreate } from 'src/app/models';
import { BookingOrigin, ExpeditionStatus, ExpeditionType } from 'src/app/models/enums';
import { objectToCamel } from 'ts-case-convert';
import { BookingsHttpService } from './bookings.http.service';
import { IBookingUpdate } from 'src/app/models/booking/bookingUpdate';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { LiteralService } from '../literal/literal.service';

@Injectable({
  providedIn: 'root',
})
export class BookingsService {
  constructor(
    private bookingsHttpService: BookingsHttpService,
    private literalService: LiteralService
  ) {}

  async getBookings(size: number, page?: number) {
    const source$ = this.bookingsHttpService.getBookings(size, page);
    const response = await lastValueFrom(source$);
    const bookingArray = this.getData(response);
    return bookingArray;
  }

  async getBookingsFiltered(
    size: number,
    page: number,
    expeditionClients: IBase[],
    expeditionTowns: IBase[],
    expeditionTypes: ExpeditionType[],
    expeditionStatus: ExpeditionStatus[],
    cancelled: boolean,
    searchValue?: string
  ) {
    const source$ = this.bookingsHttpService.getBookingsFiltered(
      size,
      page,
      expeditionClients,
      expeditionTowns,
      expeditionTypes,
      expeditionStatus,
      cancelled,
      searchValue
    );
    const response = await lastValueFrom(source$);
    const bookingArray = this.getData(response);
    return bookingArray;
  }

  async getBooking(bookingId: number, cancelled?: boolean) {
    const source$ = this.bookingsHttpService.getBooking(bookingId, cancelled);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }

  async createBooking(createBookingRequest: IBookingCreate) {
    const source$ = this.bookingsHttpService.createBooking(createBookingRequest);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }

  async multipleBooking(createBookingRequest: IBookingCreate[]) {
    const source$ = this.bookingsHttpService.multipleBooking(createBookingRequest);
    const response = await lastValueFrom(source$);
    const bookings = response as object;
    return bookings;
  }
  
  async updateBooking(updateBookingRequest: IBookingUpdate)  {
    const source$ = this.bookingsHttpService.updateBooking(updateBookingRequest);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as IBookingDetails);
    return booking;
  }

  async deleteBooking(bookingId: number, comment: string) {
    const source$ = this.bookingsHttpService.deleteBooking(bookingId, comment);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }

  async updateMissed(bookingId: number) {
    const source$ = this.bookingsHttpService.updateMissed(bookingId);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }
  
  async updateKms(bookingId: number, kms: number) {
    const source$ = this.bookingsHttpService.updateKms(bookingId, kms);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as IBookingDetails);
    return booking;
  }

  async updateNotified(bookingId: number, isNotified: boolean) {
    const source$ = this.bookingsHttpService.updateNotified(bookingId, isNotified);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }

  async isExceedingKmLimit(userId: number, inStopId?: number, outStopId?: number, serviceId?: number, nBookings?: number) {
    let isExceeded = false;
    if (inStopId && outStopId && serviceId && nBookings) {
      const source$ = this.bookingsHttpService.isExceedingKmLimit(userId, inStopId, outStopId, serviceId, nBookings);
      const response = await lastValueFrom(source$);
      isExceeded = response as boolean
    }
    return isExceeded;
  }

  async updatePaidDateTime(bookingId: number, paid: boolean) {
    const source$ = this.bookingsHttpService.updateTicketPaidDateTime(bookingId, paid);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }

  async updateValidatedDateTime(bookingId: number, validated: boolean) {
    const source$ = this.bookingsHttpService.updateTicketValidatedDateTime(bookingId, validated);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }

  getChannelTooltipText(type: BookingOrigin, notified?: boolean, userPhone?: string) {
    switch (type) {
      case BookingOrigin.BackOffice:
        if (notified === null) {
          return `<strong>${this.literalService.get('bookings.name', true)}: </strong>${this.literalService.get('bookings.actions.notifiedBooking.types.call', true)}`;
        }
        return `<strong>${this.literalService.get('bookings.name', true)}: </strong>${this.literalService.get('bookings.actions.notifiedBooking.types.call', true)}</br><strong>${this.literalService.get('bookings.actions.notifiedBooking.types.notifiedText', true)}: </strong>${this.literalService.get(notified ? 'bookings.actions.notifiedBooking.types.notified' : 'bookings.actions.notifiedBooking.types.notNotified', true)}</br><strong>${this.literalService.get('bookings.actions.notifiedBooking.types.phone', true)}:</strong> ${userPhone || '-'}`;
      case  BookingOrigin.UserApp:
        return `<strong>${this.literalService.get('bookings.name', true)}: </strong>${this.literalService.get('bookings.actions.notifiedBooking.types.app', true)}`;
      case  BookingOrigin.WhatsApp:
        return `<strong>${this.literalService.get('bookings.name', true)}: </strong>${this.literalService.get('bookings.actions.notifiedBooking.types.whatsapp', true)}`;
      default:
        return `<strong>${this.literalService.get('bookings.name', true)}: </strong>${this.literalService.get('bookings.actions.notifiedBooking.types.call', true)}`;
    }
  };

  getData(response: any) {
    return response.data;
  }
}
