import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { INotification } from 'src/app/models';
import { NotificationTypes } from 'src/app/models/enums';
import { AuthService, NotificationService, RoleService, StorageService, UsersService, UtilsService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { initialNotificationState } from 'src/app/store/initialisation/notification';
import { images } from 'src/images';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    standalone: false
})
export class SignInComponent {
  public notification: INotification = initialNotificationState;
  public images = images;

  public username = '';
  public password = '';

  public doingLogin = false;

  public loadingLogin: AnimationOptions = {
    path: '/assets/animations/loading-login-green.json'
  };

  constructor(
    public literalService: LiteralService,
    public authService: AuthService,
    private storageService: StorageService,
    public router: Router,
    public utilsService: UtilsService,
    private roleService: RoleService,
    public usersService: UsersService
  ) {
    if (this.authService.isAuthenticated()) {
      this.router.navigate([localStorage.getItem('route') || 'users']);
    }
  }

  setPassword = (event: any) => {
    this.password = event.value;
  };

  public async onSubmit() {
    this.doingLogin = true;
    await this.authService.login(this.username, this.password).then(() => { }, () => { });
    const token = await this.storageService.getToken();
    if (token) {
      await this.roleService.updateUserPermissions();
      const storedTowns = await this.storageService.getArea();
      if (!storedTowns) {
        const town = await this.usersService.getLoggedUserTown();
        if (town) this.storageService.setArea(JSON.stringify([town]));
      }
      const route = localStorage.getItem('route');
      if (route) this.router.navigate([route]);
      else this.router.navigate(['users']);
    } else {
      this.utilsService.showNotification(images.notification.error, 'login.error', NotificationTypes.DANGER);
    }
    this.doingLogin = false;
  }
}
