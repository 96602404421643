import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { IServiceAvailability } from 'src/app/models';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-change-booking-availability',
  templateUrl: './modal-change-booking-availability.component.html',
  styleUrl: './modal-change-booking-availability.component.scss',
})
export class ModalChangeBookingAvailabilityComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() element: any;
  @Input() booking: IBookingDetails;
  @Output() action = new EventEmitter<any>();

  public availabilitySelected: IServiceAvailability;
  public images = images;

  constructor(public literalService: LiteralService) {}

  set = async (event: any) => {
    this.availabilitySelected = event;
  };

  onSubmit = () => {
    this.booking.availability = this.availabilitySelected;
    this.action.emit(this.booking);
    this.modal.toggle();
  };
}
