import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { IBase } from 'src/app/models';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { images } from 'src/images';
import { TooltipPosition } from '../tooltip/tooltip-position';
import { InputType } from 'src/app/models/enums';
import { ServiceTagComponent } from 'src/app/components/service-tag/service-tag.component';
import { BookingSeatsComponent } from 'src/app/components/booking/booking-seats/booking-seats.component';
import { ColorComponent } from 'src/app/components/color/color.component';
import { RoleService, StorageService } from 'src/app/services';
import moment from 'moment';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
    standalone: false
})
export class DetailComponent implements OnInit, OnChanges {
  
  @Input() data: any;
  @Input() value?: any;

  public images = images;
  public textValues = '';
  public text = '';

  public editable = false;

  public moment = moment;
  public TooltipPosition = TooltipPosition;
  public InputType = InputType;
  public ServiceTagComponent = ServiceTagComponent;
  public BookingSeatsComponent = BookingSeatsComponent;
  public ColorComponent = ColorComponent;
  
  constructor(
    public literalService: LiteralService,
    public utilsService: UtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    private storageService: StorageService,
    private roleService: RoleService
  ) {}

  async ngOnInit() {
    this.data.array ? this.getTextFromArray() : this.getText();
    // this.data.array ? this.textValues = this.utilsService.getTextFromArray(this.data.value, this.textValues, this.data.value, this.translateValue!) || ''; : this.getText();
    this.changeDetectorRef.detectChanges();
    this.editable = await this.showDetailsForEdit();
  }

  ngOnChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  getText() {
    this.text = '';
    this.text = this.data.value;
  }

  // getTextFromArray() {
  //   this.textValues = '';
  //   this.data.value &&
  //     this.data.value.sort(
  //       (firstItem: IBase, secondItem: IBase) => firstItem.id! - secondItem.id!,
  //     );
  //   this.data.value &&
  //     this.data.value.map((value: any) => {
  //       const isLast = value.id === this.data.value?.at(-1)?.id;
  //       this.textValues += this.data.dataToShow
  //         ? value[this.data.dataToShow] + (!isLast ? ', ' : '')
  //         : value.name + (!isLast ? ', ' : '');
  //     });
  //   this.textValues === '' ? (this.textValues = '-') : null;
  // }

  getTextFromArray() {
    this.textValues = '';
    
    if (!this.data.value?.length) {
      this.textValues = '-';
      return;
    }
  
    this.data.value.sort((a: IBase, b: IBase) => a.id! - b.id!);

    this.textValues = this.data.value
      .map((value: any) => this.literalService.get(value[this.data.dataToShow] || value.name || value.id || value.label || value, true))
      .join(', ') || '-';
  }

  getTextColor(color: string) {
    return color && this.literalService.get(`colors.${color}`, true);
  }

  showDetailsForEdit = async () => {
    const routeName = await this.storageService.getRouteName();
    // TODO: Uncomment this when we use me/permissions
    // return routeName === 'bookings' && this.roleService.permissions['RESERVATION']['UPDATE'];
    return routeName === 'bookings';
  };
}
