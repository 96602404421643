<div [id]="'input-'+title" class="relative flex justify-end items-center">
  <input id="datepicker" type="text" [value]="datesToDisplay" class="block w-full h-12 bg-white border rounded-s rounded-e  text-base font-normal shadow-sm p-2.5 truncate pr-8" [placeholder]="utilsService.capitalize(literalService.get(placeholder ?? 'calendar.selectDate'))" (focus)="handleCalendar(true)" autocomplete="off" />
  <img [src]="images.user.calendar" class="absolute mr-2" height="20" width="20" alt="calendar" />
</div>
<div [id]="'dropdown-'+title" #calendarComponent class="calendar absolute z-50" [ngClass]="{'showOnTop': showOnTop}" [hidden]="!showCalendar">
  <div class="monthYear uppercase">
    <div class="nav" (click)="calendar.previousMonth()">
        <img [src]="images.arrowLeft" alt="prevMonth" class="w-6 h-6" />
    </div>
    <div>{{ literalService.get('calendar.months.' + calendar.displayMonth().toLowerCase(), true) }} {{ calendar.displayYear() }}</div>
    <div class="nav" (click)="calendar.nextMonth()">
        <img [src]="images.arrowRight" alt="nextMonth" class="w-6 h-6" />
    </div>
  </div>
  @if (showYear) {
    <div id="goToYear" class="flex justify-center items-center mb-2">
      <label class="mr-2 capitalize">{{ literalService.get('calendar.year') }}</label>
      <select class="w-full simple block px-3 py-2 bg-white border rounded-s rounded-e placeholder-slate-400 focus:outline-none" (change)="changeYear($event.target)">
        <option [value]="year.id" [selected]="year.id === getYear()" *ngFor="let year of years">
            <label>{{ year.name }}</label>
        </option>
      </select>
    </div>
  }
  @if (possibleMultipleDates) {
    <div class="flex justify-end mr-1 mb-2">
      <img [src]="images.multicheck" class="w-6 h-6 cursor-pointer" [ngClass]="{'multipleDatesEnabled': fullData.multipleDates, 'multipleDatesDisabled': !fullData.multipleDates}" (click)="changeMultipleDates()" />
    </div>
  }
  <div class="days">
    @for (weekDay of calendar.calWeekDays; track $index) {
      <span>{{ literalService.get('calendar.weekDays.' + weekDay.toLowerCase(), true) }}</span>
    }
  </div>
  <div class="dates">
    @for (item of utilsService.counter(calendar.daysInMonth()); track $index) {
      <button type="button" [ngStyle]="{'grid-column': $index === 0 ? calendar.firstDayNumber() : null}" [ngClass]="{ 'selected': selectedDate($index + 1), 'disabled': isDisabled($index + 1), 'notDisabled': !isDisabled($index + 1) }" (click)="!isDisabled($index + 1) ? selectDay($index + 1) : null">
          <time [ngClass]="{'disabled': isDisabled($index + 1)}">{{ $index + 1 }}</time>
      </button>
    }
  </div>
  <div class="flex justify-center align-middle" *ngIf="isRange">
    <input type="button" id="btnClose" class="uppercase font-bold mt-5 mr-2.5 border-2 h-10 w-28 secondary cursor-pointer" [value]="literalService.get('cancel', true)" />
    <input type="button" class="uppercase font-bold mt-5 ml-2.5 text-white h-10 w-28 primary cursor-pointer" [value]="literalService.get('apply', true)" />
  </div>
</div>