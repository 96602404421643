import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Modal } from 'flowbite';
import { IFavourites } from 'src/app/models';
import { LiteralService } from 'src/app/services';
import { IFavouritesState } from 'src/app/store/states';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-favourite-routes',
    templateUrl: './modal-favourite-routes.component.html',
    styleUrl: './modal-favourite-routes.component.scss',
    standalone: false
})
export class ModalFavouriteRoutesComponent {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() text: string;
  @Input() favourites: IFavouritesState;
  @Input() isFromUser: boolean;
  @Output() book = new EventEmitter<IFavourites>();
  @Output() delete = new EventEmitter();
  @Output() goToNewFavouriteEmitter = new EventEmitter();
  @Output() changePaginationEmitter = new EventEmitter();

  public selectedFavourite: IFavourites | undefined;

  public images = images;

  @ViewChild('form') form: ElementRef;

  constructor(
    public literalService: LiteralService
  ) { }

  selectFavouriteRoute(favourite: IFavourites) {
    if (this.selectedFavourite?.id === favourite.id) {
      this.selectedFavourite = undefined;
    } else {
      this.selectedFavourite = favourite;
    }
  };
  
  onDelete() {
    this.delete.emit(this.selectedFavourite!.id);
  };

  newBooking() {
    this.book.emit(this.selectedFavourite);
  };

  onNewFavourite() {
    this.goToNewFavouriteEmitter.emit();
  }

  onChangePagination(event: any) {
    this.selectedFavourite = undefined;
    this.changePaginationEmitter.emit(event);
  }

  onModalClick(event: any) {
    const formElement = this.form.nativeElement as HTMLElement;
    if (!formElement?.contains(event.target)) {
      this.selectedFavourite = undefined;
    } 
    event.stopPropagation();
  }

  closeModal(): void {
    this.selectedFavourite = undefined;
    this.modal.hide();
  }

}
