@if (!isFullMap) {
  <section class="mt-28 ml-28 mr-28 h-full">
    <app-booking-search-bar
      [placeholder]="literalService.get('bookings.search', true)"
      [searching]="searching"
      (addBookingEventEmitter)="goToNewBooking()"
      (filter)="filter($event)"
      (searchEventEmitter)="search($event)"
    ></app-booking-search-bar>
  </section>
  <section class="mt-5 ml-28 mr-28 h-full">
    @if (bookings.value.length) {
      <div class="grid grid-cols-12 mb-4 text-center">
        <label class="ml-14 flex justify-center col-span-2 font-semibold text-base">
          <img [src]="images.booking.user" alt="user" class="h-6 w-6 mr-2" />
          {{ literalService.get("bookings.data.user", true) }}
        </label>
        <label class="flex justify-center col-span-2 font-semibold text-base">
          <img [src]="images.booking.service" alt="service" class="h-6 w-6 mr-2" />
          {{ literalService.get("bookings.data.service", true) }}
        </label>
        <label class="flex justify-center col-span-1 font-semibold text-base">
          <img [src]="images.booking.date" alt="date" class="h-6 w-6 mr-2" />
          {{ literalService.get("bookings.data.date", true) }}
        </label>
        <label class="flex justify-center col-span-4 font-semibold text-base">
          <img [src]="images.booking.stops" alt="stops" class="h-6 w-6 mr-2" />
          {{ literalService.get("bookings.data.stops", true) }}
        </label>
        <label class="flex justify-center col-span-1 font-semibold text-base">
          <img [src]="images.booking.seats" alt="seats" class="h-6 w-6 mr-2" />
          {{ literalService.get("bookings.data.seats", true) }}
        </label>
        <label class="flex justify-center col-span-2 font-semibold text-base">
          <img [src]="images.moreOptions" alt="moreOptions" class="h-6 w-6 mr-2" />
          {{ literalService.get("moreOptions", true) }}
        </label>
      </div>
    }
    <section id="bookingsContainer">
      @for (booking of bookings.value; track $index) {
        <div *ngIf="!bookings.isFetchingBookings">
          <div
            id="{{'booking'+booking.id}}"
            class="bookingRow border rounded pt-2 pb-2 grid grid-cols-12 justify-center items-center mb-4"
            [class.even]="$index % 2 === 0" 
            [class.blocked]="booking.missed"
          >
            <div class="flex h-12 item col-span-2 line-clamp-2 truncate max-h-16 items-center">
              <div class="border-r-[1px] ml-4 h-full min-w-10" (click)="showNotifiedModal($event, booking.id!, booking.cancelled)">
                @if (booking.channel === BookingOrigin.BackOffice) {
                  @if (booking.tripStatus === ExpeditionStatus.READY && booking.notified) {
                    <img [src]="images.booking.notified.notified" [ngClass]="{'cancelled': booking.cancelled}" class="w-6 mr-5 h-full" alt="notified" [tooltip]="getTooltipText(BookingOrigin.BackOffice, booking.notified)" [position]="TooltipPosition.DYNAMIC" />
                  } @else if (booking.tripStatus === ExpeditionStatus.READY && !booking.notified) {
                    <img [src]="images.booking.notified.notNotified" [ngClass]="{'cancelled': booking.cancelled}" class="w-6 mr-3 h-full" alt="notNotified" [tooltip]="getTooltipText(BookingOrigin.BackOffice, booking.notified)" [position]="TooltipPosition.DYNAMIC" />     
                  } @else {
                    <img [src]="images.booking.notified.phone" [ngClass]="{'cancelled': booking.cancelled}" class="w-6 mr-3 h-full" alt="phone" [tooltip]="getTooltipText(BookingOrigin.BackOffice)" [position]="TooltipPosition.DYNAMIC" />
                  }
                } @else if (booking.channel === BookingOrigin.UserApp) {
                  <img [src]="images.booking.notified.app" [ngClass]="{'cancelled': booking.cancelled}" class="w-6 mr-3 h-full" alt="app" [tooltip]="getTooltipText(BookingOrigin.UserApp)" [position]="TooltipPosition.DYNAMIC" />
                } @else if (booking.channel === BookingOrigin.WhatsApp) {
                  <img [src]="images.booking.notified.whatsapp" [ngClass]="{'cancelled': booking.cancelled}" class="w-6 mr-3 h-full" alt="whatsApp" [tooltip]="getTooltipText(BookingOrigin.WhatsApp)" [position]="TooltipPosition.DYNAMIC" />
                } @else {
                  <img [src]="images.booking.notified.phone" [ngClass]="{'cancelled': booking.cancelled}" class="w-6 mr-3 h-full" [ngClass]="{'cancelled': booking.cancelled}" alt="phone"/>
                }
              </div>
              <label [id]="'user-'+booking.id" class="truncate whitespace-pre-line line-clamp-2 w-full px-2 font-semibold justify-center items-center text-base " 
                [tooltip]="utilsService.visibleTooltip('user-'+booking.id) ? booking.firstName + ' ' + booking.lastName : ''" [visibleTooltip]="utilsService.visibleTooltip('user-'+booking.id)" [position]="TooltipPosition.DYNAMIC"
                [ngClass]="{'neutrals-grey-04-text': booking.cancelled}">
                <div (click)="showBookingData(booking.id!, booking.cancelled)">
                  {{ booking.firstName }} {{ booking.lastName }}
                </div>
              </label>
            </div>
            <div class="item col-span-2" (click)="showBookingData(booking.id!, booking.cancelled)">
              <label class="flex h-12 pr-5 pl-5 font-semibold text-base justify-between items-center cursor-pointer">
                <app-service-tag class="justify-self-start" [serviceInfo]="booking.service" />
                <span [id]="'service-'+booking.id" [ngClass]="{'neutrals-grey-04-text': booking.cancelled}" class="m-2 line-clamp-3" [tooltip]="utilsService.visibleTooltip('service-'+booking.id) ? booking.service.name! : ''" [visibleTooltip]="utilsService.visibleTooltip('service-'+booking.id)" [position]="TooltipPosition.DYNAMIC">{{ booking.service && booking.service.name }}</span>
                <div></div>
              </label>
            </div>
            <div class="item col-span-1" (click)="showBookingData(booking.id!, booking.cancelled)">
              <label class="flex h-12 truncate pr-5 pl-5 justify-center items-center font-semibold text-base cursor-pointer" [ngClass]="{'neutrals-grey-04-text': booking.cancelled}">
                {{ booking.date | date: 'dd/MM/yyyy' }}
              </label>
            </div>
            <div class="item col-span-4 font-semibold text-base cursor-pointer" (click)="showBookingData(booking.id!, booking.cancelled)">
              <app-booking-stops [booking]="booking" [origin]="'origin'" />
              <app-booking-stops [booking]="booking" [destination]="'destination'" />
            </div>
            <div class="item truncate h-12 pr-5 pl-5 col-span-1 font-semibold text-base cursor-pointer" (click)="showBookingData(booking.id!, booking.cancelled)">
              <app-seats [booking]="booking" />
            </div>
            <div class="flex justify-center items-center col-span-2 cursor-default">
              @if (booking.userType !== userType.Driver) {
                <img [src]="images.user.star" alt="bookingFavorite" class="h-6 w-6 mr-3 cursor-pointer" [tooltip]="literalService.get('bookings.tooltips.favorite', true)" [position]="TooltipPosition.DYNAMIC" (click)="bookingSelectedForFavourite = booking; createFavouriteModal.toggle()" />
              } @else {
                <img [src]="images.user.star" alt="bookingFavorite" class="h-6 w-6 mr-3 cursor-pointer cancelled"  />
              }
              @if (!booking.cancelled) {
                @if (booking.userType !== userType.Driver) {
                  <img [src]="images.booking.rebook" alt="rebook" class="h-6 w-6 mr-4 cursor-pointer"  [tooltip]="literalService.get('bookings.tooltips.rebook', true)" (click)="rebook(booking)"/>
                } @else {
                  <img [src]="images.booking.rebook" alt="rebook" class="h-6 w-6 mr-4 cursor-pointer cancelled"/>
                }
                <img [src]="images.trips.name" alt="bookingTrip" class="h-5 w-5 mr-4 cursor-pointer" [tooltip]="literalService.get('bookings.tooltips.trip', true)" (click)="goToTrip(booking.tripId)"/>
              }
              @else {
                @if (booking.userType !== userType.Driver) {
                  <img [src]="images.booking.rebook" alt="rebook"  class="h-6 w-6 mr-4 cancelled" />
                } @else {
                  <img [src]="images.booking.rebook" alt="rebook" class="h-6 w-6 mr-4 cursor-pointer"/>
                }
                <img [src]="images.trips.name" alt="bookingTrip" class="h-5 w-5 mr-4 cancelled"/>
              }    
              <img [src]="images.pax" alt="bookingUser" class="h-6 w-6 cursor-pointer" [tooltip]="literalService.get('bookings.tooltips.user', true)" (click)="goToUser(booking.userId)"/>
            </div>
          </div>
        </div>
      } @empty {
        @if (!searching) {
          <div class="justify-center items-center flex">
            <img [src]="images.booking.empty" class="h-48" />
          </div>
          <div class="justify-center items-center flex mt-4">
              <label class="font-bold text-lg text-center uppercase neutrals-grey-04-text">{{ literalService.get('bookings.noBookings') }}</label>
          </div>
        }
      }
    </section>
    <app-pagination [values]="bookings" (changePage)="changePage($event)" />
    <app-booking-details [booking]="bookingSelected"
                         (openInFullMap)="openInFullMap($event)"
                         (editBooking)="editBooking()"
                         (updateBookings)="getBookings()"
                         (deleteBooking)="deleteBookingModal.toggle()"
                         (markAsAFault)="markAsAFault()"
                         (closeDetails)="closeDetails()">
                        </app-booking-details>
  </section>
} @else {
  <div id="mapContainer" class="pt-20 pl-20 h-[1000px]" [ngClass]="{'hidden': !isFullMap}">
    <img [src]="images.close" class="absolute bg-white rounded-[50%] z-10 h-8 w-8 right-5 p-1 mt-4 mr-4 cursor-pointer" (click)="close()" />
    @if (edit || newBooking) {
      <app-booking-modal class="absolute mt-5 ml-5 z-10" [newBooking]="newBookingObject" [creatingBooking]="creatingBooking" [bookingFromFavourite]="bookingFromFavourite" [hasAlterations]="hasAlterations" [closeExceedingKm]="closeExceedingKmHandler" (selectODEventEmitter)="selectOD($event)" (getServiceInfosEventEmitter)="getServiceInfos($event)" (goToList)="bookingCreated()" (showMaxReservationTimeModalEventEmitter)="showMaxReservationTimeModal()" (showExceedingKmLimitModalEventEmitter)="showExceedingKmLimitModal($event)" (showBlockedUserModalEventEmitter)="showBlockedUserModal($event)" (selectStop)="selectStop($event)" (openFavourite)="openFavourite($event)" (changeTown)="changeTown()" (showAlterationModal)="alterationModal.toggle()"></app-booking-modal>
    }
    @if (edit || newBooking) {
      <div id="newMarkers"></div>
      <app-marker-od id="origin" class="hidden" [textColor]="'text-white'" [color]="'primary-purple-light-background'" [text]="literalService.get('origin').toUpperCase()"></app-marker-od>
      <app-marker-od id="destination" class="hidden" [textColor]="'text-black'" [color]="'primary-green-background'" [text]="literalService.get('destination').toUpperCase()"></app-marker-od>
    }
    <app-marker-stop [id]="'stopMarker'" class="invisible" [color]="'#000000'"></app-marker-stop>
    <app-marker-stop-disabled [id]="'stopMarkerDisabled'" class="invisible" [color]="'#848ea8'"></app-marker-stop-disabled>
    <div id="map" class="w-full h-full"></div>
  </div>
}
<app-modal-confirmation-text [id]="'deleteBooking'" [modal]="deleteBookingModal" [hidden]="!deleteBookingShowed" [text]="'bookings.actions.deleteBooking.description'" [title]="'bookings.actions.deleteBooking.name'" [reason]="reason" (action)="deleteBookingAction($event)" [deleting]="deletingBooking" />
<app-modal-max-reservation-time [id]="'maxReservationTime'" [modal]="maxReservationTimeModal" [img]="images.booking.maxReservationTime" [title]="literalService.get('bookings.maxReservationTime.title', true)" [text]="literalService.get('bookings.maxReservationTime.text', true)" [hidden]="!maxReservationTimeShowed"></app-modal-max-reservation-time>
<app-modal-exceeding-km-limit [id]="'exceedingKmLimit'" [modal]="exceedingKmLimitModal" [img]="images.booking.warning" [title]="literalService.get('bookings.kmLimitExceeded.title', true)" [text]="literalService.get('bookings.kmLimitExceeded.text', true)" [hidden]="!exceedingKmLimitShowed" [newBooking]="newBookingObject" [kmDone]="distanceTravelled" [kmLimit]="maxKmsPerMonth" (action)="createBooking($event)" (close)="closeExceedingKm()"></app-modal-exceeding-km-limit>
<app-modal-blocked-user-booking [id]="'blockedUserBooking'" [modal]="blockedUserModal" [img]="images.booking.warning" [title]="literalService.get('bookings.userPenalized.title', true)" [text]="literalService.get('bookings.userPenalized.text', true)" [hidden]="!blockedUserShowed" [newBooking]="newBookingObject" [penaltyReason]="penaltyReason" [penaltyDate]="penaltyEndDate" (action)="back()"></app-modal-blocked-user-booking>
<app-modal-confirmation [id]="'markAsFault'" [modal]="markAsFaultModal" [hidden]="!markAsFaultModalShowed" [text]="(bookingSelected && bookingSelected.missed) ? 'bookings.actions.unMarkAsAFault.modalText' : 'bookings.actions.markAsAFault.modalText'" [title]="(bookingSelected && bookingSelected.missed) ? 'bookings.actions.unMarkAsAFault.modalTitle' : 'bookings.actions.markAsAFault.modalTitle'" (action)="markAsAFault()" />
<app-modal-create-favourite [id]="'createFavourite'" [modal]="createFavouriteModal" [title]="literalService.get('bookings.actions.createFavourite.title')" [text]="literalService.get('bookings.actions.createFavourite.text')" [hidden]="!createFavouriteShowed" [creating]="creatingFavourite" (action)="createFavourite($event)"></app-modal-create-favourite>
<app-modal-favourite-routes [id]="'viewFavourites'" [modal]="viewFavourites" [hidden]="!viewFavouritesShowed" [favourites]="favourites" (book)="bookWithFavourite($event)" (delete)="deleteFavourite($event)"></app-modal-favourite-routes>
<app-modal-alteration [id]="'alteration'" [modal]="alterationModal" [title]="literalService.get('bookings.alterations.title', true)" [text]="'BBBB'" [services]="serviceInfos"></app-modal-alteration>
<app-modal-notified-booking [id]="'notifiedBooking'" [modal]="notifiedBookingModal" [hidden]="!notifiedBookingShowed" [booking]="bookingSelectedForNotified" (action)="modifyNotified($event)" />
