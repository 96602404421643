<div class="flex">
  <section class="w-[30rem]" [ngClass]="{'h-auto max-h-[calc(100vh-80px)]': loadingAvailabilities}">
    <article id="header" class="flex primary-green-2-background rounded-ss-lg rounded-se-lg">
      <div class="mt-4 ml-8 mb-4 w-full">
        @if (currentStep === 1) {
          <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('bookings.newBooking', true) }}</h1>
          <label class="font-semibold text-lg">{{ literalService.get('bookings.information', true) }}</label>
        } @else if (currentStep === 2) {
          <div class="flex items-center">
            <img [src]="images.arrowLeft" alt="back" class="h-6 w-6 cursor-pointer" (click)="back()" />
            <div class="ml-5">
              <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('bookings.newBooking', true) }}</h1>
              <label class="font-semibold text-lg">{{ literalService.get('bookings.information', true) }}</label>
            </div>
          </div>
        } @else if (currentStep === 3) {
          <div class="flex items-center">
            <img [src]="images.arrowLeft" alt="back" class="h-6 w-6 cursor-pointer" (click)="back()" />
            <div class="ml-5">
              <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('bookings.newBooking', true) }}</h1>
              <label class="font-semibold text-lg">{{ literalService.get('bookings.availabilities', true) }}</label>
            </div>
          </div>
        }
      </div>
      <div class="mt-4 mr-5 justify-end">
        <div class="flex main rounded-[50%] h-14 w-14 justify-center items-center">
          <div class="quarter quarter1" [ngClass]="{ 'block': steps.includes(1), 'hidden': !steps.includes(1) }"></div>
          <div class="quarter quarter2" [ngClass]="{ 'block': steps.includes(2), 'hidden': !steps.includes(2) }"></div>
          <div class="quarter quarter3" [ngClass]="{ 'block': steps.includes(3), 'hidden': !steps.includes(3) }"></div>
          <label class="flex font-bold text-[22px] primary-purple-text cutout justify-center items-center">
            {{ currentStep }}/{{ numberSteps }}
          </label>
        </div>
      </div>
    </article>
    @if (currentStep === 3 && !loadingAvailabilities && (availabilities.failure.length > 0 || availabilities.success.length > 0)) {
      <article class="flex items-center primary-purple-light-2-background p-2 pl-8">
        <div class="flex items-center">
          <img [src]="images.booking.date" class="h-6 w-6 mr-2" />
          <label class="font-normal text-base mr-6">{{ getDate() }}</label>
          <img [src]="images.pax" class="h-6 w-6 mr-1" />
          <label class="font-normal text-base mr-2">{{ pax }}</label>
          <img [src]="images.prm" class="h-6 w-6 mr-1" />
          <label class="font-normal text-base">{{ prm }}</label>
        </div>
        <div class="felx absolute right-0 mr-12" (click)="currentPage > 0 && currentPage = currentPage - 1" [ngClass]="{'hidden': !isManualBooking}">
          <label class="font-normal text-base">{{ currentElement }} - {{ totalElements }}</label>
        </div>
        <div class="cursor-pointer absolute right-0 mr-12" (click)="currentPage > 0 && currentPage = currentPage - 1">
          <img [src]="images.arrowLeft" class="h-5 w-5" [ngClass]="{'opacity-40': currentPage === 0, 'hidden': isManualBooking, 'mr-8': !hasAlterations, 'mr-20': hasAlterations}" />
        </div>
        <div class="cursor-pointer absolute right-0 mr-8" (click)="(currentPage < Object.keys(availabilitiesSeparateByDate).length - 1) && currentPage = currentPage + 1">
          <img [src]="images.arrowRight" class="h-5 w-5" [ngClass]="{'opacity-40': currentPage === Object.keys(availabilitiesSeparateByDate).length - 1, 'hidden': isManualBooking, 'mr-8': !hasAlterations, 'mr-20': hasAlterations}" />
        </div>
      </article>
    }
    @if (currentStep === 1 || currentStep === 2) {
      <article id="body" class="bg-white pb-6 pt-1 pl-7 pr-7">
        @for (element of bookingForm; track $index) {
          <div class="mt-4" [ngClass]="{'hidden': !steps.includes(element.step) }">
            <app-input  [data]="element"
                        [edit]="true"
                        [independent]="true"
                        (setEventEmitter)="set($event.value, element.edit?.valueToChange)"
                        (scrollEventEmitter)="scroll($event)"
                        (searchEventEmitter)="search($event)"
                        (changeEventEmitter)="change($event, element.edit?.valueToChange)" />
            @if (element.title === 'bookings.data.originStop') {
              <button class="absolute flex justify-center items-center rounded-full neutrals-grey-01-background h-7 w-7 right-0 mt-2" [ngClass]="{'hidden': !possibleSwap, 'mr-8': !hasAlterations, 'mr-28': hasAlterations}" (click)="changeStops()">
                <img [src]="images.booking.changeStops" class="h-6 w-6 rotate-90 p-[2px]" />
              </button>
            }
            @if (element.title === 'bookings.data.originStop' && stopOriginNotification) {
              <app-input-notifications class="absolute" [ngClass]="{ 'fadeIn': stopOriginNotification, 'fadeOut': !stopOriginNotification }" [title]="element.title+'IncompatibleTitle'" [text]="element.title+'IncompatibleText'"></app-input-notifications>
            }
            @if (element.title === 'bookings.data.destinationStop' && stopDestinationNotification) {
              <app-input-notifications class="absolute" [ngClass]="{ 'fadeIn': stopDestinationNotification, 'fadeOut': !stopDestinationNotification }" [title]="element.title+'IncompatibleTitle'" [text]="element.title+'IncompatibleText'"></app-input-notifications>
            }            
            @if (element.informativeToNoChange) {
              <div class="inline-flex p-1 cursor-pointer neutrals-grey-01-background rounded justify-end items-center gap-1" (click)="openFavourite.emit(newBooking.user.id)">
                  <img [src]="images.user.star" class="w-6 h-6 mr-1 cursor-pointer" />
                  <div class="neutrals-grey-06-text text-base font-normal">{{ literalService.get('favourites.routes', true)}}</div>
              </div>
            }
          </div>
        }
      </article>
      <article id="footer" class="flex bg-white pb-6 justify-center rounded-es-lg rounded-ee-lg pt-1 pl-7 pr-7" [ngClass]="{'hidden': !newBooking.targetUserId }">
        <input type="button" class="primary text-white gap-4 border-2 font-semibold h-14 w-3/4  uppercase cursor-pointer" [disabled]="disabledViewAvailabilities()" (click)="getAvailabilities()" [value]="literalService.get('bookings.viewAvailabilities', true)" />
      </article>
    } @else if (currentStep === 3) {
      @if (loadingAvailabilities) {
        <article class="flex bg-white w-full pb-6 pt-4 pl-7 pr-7 justify-center rounded-es-lg rounded-ee-lg">
            <ng-lottie [options]="optionsPin" (animationCreated)="animationCreated($event)" />
        </article>
      } @else if (creatingBooking) {
        <article class="flex bg-white w-full pb-6 pt-4 pl-7 pr-7 justify-center rounded-es-lg rounded-ee-lg">
          <ng-lottie [options]="optionsBus" (animationCreated)="animationCreated($event)" />
        </article>
      } @else {
        <article id="body" class="bg-white pb-6 pt-1 pl-7 pr-7">
          <app-booking-availabilities [availabilities]="availabilitiesSeparateByDate[availabilitiesDates[currentPage]]" [newBooking]="newBooking" [serviceInfos]="serviceInfos" [loadingAvailabilities]="loadingAvailabilities" (manual)="manualBooking()" (book)="createBooking($event)" (bookMultiple)="bookMultiple($event)" (showMaxReservationTimeModalEventEmitter)="showMaxReservationTimeModal()" (showExceedingKmLimitModalEventEmitter)="showExceedingKmLimitModal($event)"></app-booking-availabilities>
        </article>
      }
    }
  </section>
  @if (hasAlterations) {
    <img [src]="images.alterationWarning" class="h-10 w-10 m-5 cursor-pointer" alt="alterationWarning" (click)="showAlterationModal.emit()" />
  }
</div>
