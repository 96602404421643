import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { Modal } from 'flowbite';
import { ClipboardService } from 'ngx-clipboard';
import { AppComponent } from 'src/app/app.component';
import { getBookingForm, getStops, getCancelledBookingForm } from 'src/app/forms/bookingForms';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { IBookingUpdate } from 'src/app/models/booking/bookingUpdate';
import { NotificationTypes, TicketingType, UserType } from 'src/app/models/enums';
import { ErrorsCode } from 'src/app/models/enums/errorsCode';
import { BookingsService, MapUtilsService, NotificationService, RoleService, RouteService, TownsService, UtilsService } from 'src/app/services';
import { FavouritesService } from 'src/app/services/favourites/favourites.service';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { environment } from 'src/environments/environment';
import { images } from 'src/images';

@Component({
    selector: 'app-booking-details',
    templateUrl: './booking-details.component.html',
    styleUrl: './booking-details.component.scss',
    standalone: false
})
export class BookingDetailsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() booking: IBookingDetails = {} as IBookingDetails;
  @Output() openInFullMap = new EventEmitter<IBookingDetails>();
  @Output() editBooking = new EventEmitter<IBookingDetails>();
  @Output() updateBookings = new EventEmitter();
  @Output() deleteBooking = new EventEmitter();
  @Output() markAsAFault = new EventEmitter();
  @Output() closeDetails = new EventEmitter();
  @Output() showTicketingStatusModal = new EventEmitter();

  public map: google.maps.Map;

  public cancellationReason = this.literalService.get('bookings.data.reason', true);

  public expanded = false;
  public changeKmsShowed = false;
  public changeSeatsShowed = false;
  public changeAvailabilityShowed = false;
  public changeOriginDestinationShowed = false;
  public updatingOriginDestination = false;
  public createFavouriteShowed = false;
  public setFaultShowed = false;
  public exceedingKmLimitShowed = false;

  public edit = false;
  public newBooking = false;
  public viewMap = false;

  public bookingForm: any;

  public bookingCopy: IBookingDetails;
  public isHoveringOriginDestinationDetail: boolean;
  public changeKms: Modal;
  public changeSeats: Modal;
  public changeAvailability: Modal;
  public changeOriginDestination: Modal;
  public createFavouriteModal: Modal;
  public setFaultModal: Modal;
  public exceedingKmLimitModal: Modal;

  public creatingFavourite = false;

  public availablityElement: any;
  public originElement: any;
  public destinationElement: any;

  public images = images;
  public TooltipPosition = TooltipPosition;

  public faultModalShow = false;

  public maxKmsPerMonth: number;
  public kmsTravelled: number;

  constructor(
    public literalService: LiteralService,
    private mapUtilsService: MapUtilsService,
    public router: Router,
    public appComponent: AppComponent,
    private clipboardService: ClipboardService,
    private routeService: RouteService,
    public roleService: RoleService,
    private notificationService: NotificationService,
    private bookingsService: BookingsService,
    public utilsService: UtilsService,
    private favouritesService: FavouritesService,
    private townsService: TownsService
  ) { }

  async ngOnInit() {
    await this.initMap();
  }

  ngAfterViewInit(): void {
    this.loadFormAndMap();
    this.initializeModals();
  }

  async ngOnChanges() {
    await this.initMap();
    this.map?.data.forEach((feature: any) => this.map.data.remove(feature));
    this.loadFormAndMap();
  }

  async initMap() {
    const { Map } = (await google.maps.importLibrary(
      'maps',
    )) as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      zoom: 15,
      disableDefaultUI: true,
      mapId: environment.mapId,
    });
  };

  async loadFormAndMap() {
    if (this.map && this.booking) {
      this.booking && this.booking.cancelled &&
        await getCancelledBookingForm(this.booking).then((res: any) => {
          this.bookingForm = res;
        });
      !this.booking.cancelled &&
      await getBookingForm(this.booking, this.literalService, this.routeService, this.townsService).then((res: any) => {
        this.bookingForm = res;
      });
      this.map.data.setStyle({
        strokeColor: this.booking.service && this.booking.service.color !== '#FFFFFFFF' ? this.booking.service.color : '#000000',
        strokeWeight: 5,
      });
      if (this.booking.path) {
        this.map.data.addGeoJson(this.booking.path);
        const bounds = new google.maps.LatLngBounds();
        const markers: any[] = [];
        const markerOrigin = document.getElementById('origin') as HTMLElement;
        markerOrigin.classList.remove('hidden');
        const markerDestination = document.getElementById('destination') as HTMLElement;
        markerDestination.classList.remove('hidden');
        markers.push({
          element: markerOrigin,
          latitude: this.booking.origin.latitude,
          longitude: this.booking.origin.longitude,
          name: this.booking.origin.name
        });
        markers.push({
          element: markerDestination,
          latitude: this.booking.destination.latitude,
          longitude: this.booking.destination.longitude,
          name: this.booking.destination.name
        });
        this.mapUtilsService.addCustomMarkers(this.map, markers);
        this.booking.path.features.map((feature: any) => {
          bounds.union(
            new google.maps.LatLngBounds(
              new google.maps.LatLng(
                feature.geometry.coordinates[0][1],
                feature.geometry.coordinates[0][0],
              ),
            ),
          );
        });
        setTimeout(() => {
          this.map.fitBounds(bounds);
        }, 500);
      }
    }
  };

  initializeModals() {
    this.changeKms = new Modal(document.getElementById('changeKms'), {
      closable: true,
      onHide: () => (this.changeKmsShowed = false),
      onShow: () => (this.changeKmsShowed = true),
    });
    this.changeSeats = new Modal(document.getElementById('changeSeats'), {
      closable: true,
      onHide: () => (this.changeSeatsShowed = false),
      onShow: () => (this.changeSeatsShowed = true),
    });
    this.changeAvailability = new Modal(document.getElementById('changeAvailability'), {
      closable: true,
      onHide: () => (this.changeAvailabilityShowed = false),
      onShow: () => (this.changeAvailabilityShowed = true),
    });
    this.changeOriginDestination = new Modal(document.getElementById('changeOriginDestination'), {
      closable: true,
      onHide: () => {
        this.originElement = null;
        this.destinationElement = null;
        this.changeOriginDestinationShowed = false
      },
      onShow: () => (this.changeOriginDestinationShowed = true),
    });
    this.createFavouriteModal = new Modal(document.getElementById('createFavouriteFromDetail'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.createFavouriteShowed = false),
      onShow: () => (this.createFavouriteShowed = true),
    });
    this.setFaultModal = new Modal(document.getElementById('setFault'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.setFaultShowed = false),
      onShow: () => (this.setFaultShowed = true),
    });
    this.exceedingKmLimitModal = new Modal(document.getElementById('exceedingKmLimitEdit'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.exceedingKmLimitShowed = false),
      onShow: () => (this.exceedingKmLimitShowed = true),
    });
  };

  closeModals() {
    if (this.changeKmsShowed) this.changeKms.toggle();
    if (this.changeSeatsShowed) this.changeSeats.toggle();
    if (this.changeAvailabilityShowed) this.changeAvailability.toggle();
    if (this.changeOriginDestinationShowed) this.changeOriginDestination.toggle();
    if (this.createFavouriteShowed) this.createFavouriteModal.toggle();
    if (this.setFaultShowed) this.setFaultModal.toggle();
  };

  async openModal(modal: any) {
    this.bookingCopy = { ...this.booking };
    this.availablityElement = this.bookingForm.filter((x: { title: string; }) => x.title === 'bookings.data.availability')[0];
    this.availablityElement.value = this.bookingCopy.availability.id;

    this.originElement = this.bookingForm.find((element: any) => element.title === 'bookings.data.originStop');
    this.originElement.value = this.bookingCopy.origin.serviceStopId;

    this.destinationElement = this.bookingForm.find((element: any) => element.title === 'bookings.data.destinationStop');
    this.destinationElement.value = this.bookingCopy.destination.serviceStopId;

    if (['origin', 'destination'].includes(modal)) {
      const originStops = await getStops(this.townsService, this.booking);
      const destinationStops = await getStops(this.townsService, this.booking, this.originElement.value);
      ['originStop', 'destinationStop'].forEach((key) => {
          const element = this.bookingForm.find((x: { title: string }) => x.title === `bookings.data.${key}`);
          if (element?.edit) element.edit = { ...element.edit, values: key === 'originStop' ? originStops : destinationStops };
          key === 'originStop' ? this.originElement = element : this.destinationElement = element;
      });
      this.changeOriginDestination.toggle();
    } else if (modal === 'kms') {
      this.booking.kms = this.booking.kms ? Number(this.booking.kms.toFixed(2)) : 0;
      this.changeKms.toggle();
    } else if (modal === 'seats') {
      this.changeSeats.toggle();
    } else if (modal === 'availability') {
      this.changeAvailability.toggle();
    } else if (modal === 'status' && this.booking.ticketingType && this.booking.ticketingType !== TicketingType.None && !(this.booking.ticketingType === TicketingType.Online && this.booking.price === null)) {
      this.showTicketingStatusModal.emit();
    }
  }

  submitEdit(booking: IBookingDetails) {
    if (this.changeOriginDestinationShowed) {
      this.submitBookingEdit(booking);
      this.changeOriginDestination.hide();
    } else {
      this.submitBookingKmsEdit(booking);
    }
  }

  async submitBookingEdit(submitBooking: IBookingDetails) {
    this.updatingOriginDestination = true;
    const bookingUpdate: IBookingUpdate = {} as IBookingUpdate;
    bookingUpdate.loggedUserId = submitBooking.userId;
    bookingUpdate.availabilityId = submitBooking.availability.id;
    bookingUpdate.tripId = submitBooking.tripId;
    bookingUpdate.bookingId = submitBooking.id;
    bookingUpdate.targetUserId = submitBooking.userId;
    bookingUpdate.availabilityId = submitBooking.availability.id;
    bookingUpdate.originStop = submitBooking.origin;
    bookingUpdate.destinationStop = submitBooking.destination;
    bookingUpdate.seats = submitBooking.seats;
    bookingUpdate.prmSeats = submitBooking.prmSeats;
    try {
      this.booking = await this.bookingsService.updateBooking(bookingUpdate);
      this.updateBookings.emit();
      this.bookingForm = await getBookingForm(this.booking, this.literalService, this.routeService, this.townsService);
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.editBooking', NotificationTypes.SUCCESS);
    } catch (error: any) {
      this.notificationService.image = images.notification.error;
      this.notificationService.title = this.literalService.get('bookings.actions.editBooking.errorTitle');
      this.notificationService.message = '0';
      this.notificationService.translate = true;
      if (error.message.includes(ErrorsCode.MAX_CAPACITY)) {
        if (submitBooking.seats === this.booking.seats && submitBooking.prmSeats !== this.booking.prmSeats) {
          this.notificationService.message = this.literalService.get('bookings.actions.editBooking.errorMaxPRMCapacity');
        } else {
          this.notificationService.message = this.literalService.get('bookings.actions.editBooking.errorMaxCapacity');
        }
      }
      this.notificationService.show(NotificationTypes.DANGER);
    }
    this.updatingOriginDestination = false;
    if (this.changeOriginDestinationShowed) this.changeOriginDestination.toggle();
  };

  async submitBookingKmsEdit(booking: IBookingDetails) {
    try {
      this.booking = await this.bookingsService.updateKms(this.booking.id!, booking.kms);
      this.bookingForm = await getBookingForm(this.booking, this.literalService, this.routeService, this.townsService);
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.editBooking', NotificationTypes.SUCCESS);
    } catch (error) {
      this.notificationService.image = images.notification.error;
      this.notificationService.title = 'errorOccurred';
      this.notificationService.message = '0';
      this.notificationService.translate = true;
      this.notificationService.show(NotificationTypes.DANGER);
    }
  };

  handleExceedingKmsLimit(booking: IBookingDetails) {
    this.kmsTravelled = booking.kms;
    this.maxKmsPerMonth = booking.service.routes[0].maxKmsPerMonth || 0;
    this.exceedingKmLimitModal.show();
  };

  closeExceedingKm() {
    this.exceedingKmLimitModal.hide();
    this.kmsTravelled = 0;
    this.maxKmsPerMonth = 0;
  };

  setIsHoveringOriginDestinationDetail(isHovering: boolean) {
    this.isHoveringOriginDestinationDetail = isHovering;
  };

  close() {
    this.expanded = false;
    this.edit = false;
    this.newBooking = false;
    this.closeDetails.emit();
  };

  goToUser(userId: number, userType: UserType): void {
    this.appComponent.setRouteName('users');
    this.router.navigate([`/users${userType === UserType.Driver ? `/${userType.toLowerCase()}` : ''}/${userId}`]);
  }

  goToTrip(tripId: number): void {
    this.appComponent.setRouteName('trips');
    this.router.navigate(['/trips/' + tripId]);
  }

  copyLink(): void {
    const domain = this.routeService.getCurrentDomain();
    let path = domain + '/bookings/' + this.booking.id + `?cancelled=${this.booking.cancelled}`;
    this.clipboardService.copyFromContent(path);
  }

  async createFavourite(favouriteName: string) {
    this.creatingFavourite = true;
    await this.favouritesService.createFavourite(favouriteName, this.booking).then(() => {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.createFavourite.success', NotificationTypes.SUCCESS);
    }, (error: any) => {
      console.log(error);
      this.creatingFavourite = false;
    });
    this.createFavouriteModal.toggle();
    this.creatingFavourite = false;
  };

  getBookingStatus(): 'disabled' | 'error' | 'warning' | 'validated' | 'ok' {
    const { ticketingType, price, paidDateTime, validatedDateTime } = this.booking;

    if (ticketingType === TicketingType.None) return 'disabled';
    if (validatedDateTime) return paidDateTime ? 'ok' : 'validated';
    if (price || [TicketingType.Hybrid, TicketingType.Onboard, TicketingType.Integrated].includes(ticketingType)) {
        return paidDateTime ? 'warning' : 'error';
    }
    return 'disabled';
  }

  getTicketProperties() {
    const status = this.getBookingStatus();
    const imageMapping = {
      disabled: this.images.booking.ticket.ticket,
      error: this.images.booking.ticket.ticketError,
      warning: this.images.booking.ticket.ticketWarning,
      validated: this.images.booking.ticket.ticketOk,
      ok: this.images.booking.ticket.ticketOk,
    };
    const ticketName = `ticket${status.charAt(0).toUpperCase() + status.slice(1)}`;
    return {
      src: imageMapping[status] || imageMapping.disabled,
      alt: ticketName,
      tooltip: this.literalService.get(`bookings.tooltips.${ticketName}`, true)
    };
  }

}
