import { Injectable } from '@angular/core';
import { FavouritesHttpService } from './favourites.http.service';
import { lastValueFrom } from 'rxjs';
import { IBooking } from 'src/app/models';
import { objectToCamel } from 'ts-case-convert';
import { initialPaginationState } from 'src/app/store/initialisation/pagination';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {

  constructor(private favouritesHttpService: FavouritesHttpService) { }

  async getFavourites(userId: number, page: number, size: number) {
    const source$ = this.favouritesHttpService.getFavourites(userId, page, size);
    const response = await lastValueFrom(source$);
    const favourites = objectToCamel(response as object);
    const favouriteArray = this.getDataWithPagination(favourites, page);
    return favouriteArray;
  };

  async getFavourite(favouriteId: number) {
    const source$ = this.favouritesHttpService.getFavourite(favouriteId);
    const response = await lastValueFrom(source$);
    const favourite = objectToCamel(response as object);
    return favourite;
  };

  async createFavourite(favouriteName: string, booking: IBooking) {
    const source$ = this.favouritesHttpService.createFavourite(favouriteName, booking);
    const response = await lastValueFrom(source$);
    const favourite = objectToCamel(response as object);
    return favourite;
  };
  
  async deleteFavourite(favouriteId: number) {
    const source$ = this.favouritesHttpService.deleteFavourite(favouriteId);
    const response = await lastValueFrom(source$);
    return response;
  };

  getData(response: any) {
    return response.data;
  };

  getDataWithPagination(response: any, page: number) {
    return {
      value: response.data.content,
      pagination: {
        ...initialPaginationState,
        numberOfElements: response.data.numberOfElements,
        totalPages: response.data.totalPages,
        totalElements: response.data.totalElements,
        first: response.data.first,
        last: response.data.last,
        actualPage: page,
        size: response.data.size
      }
    };
  }
}
