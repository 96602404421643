import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import {
  IApps,
  IBase,
  IClient,
  IOperator,
  ITown,
  IUserDriver,
  IUserRegular,
} from 'src/app/models';
import {
  Gender,
  InputType,
  NotificationTypes,
  UserRole,
  UserStatus,
  UserType,
} from 'src/app/models/enums';
import {
  NotificationService,
  StorageService,
  UsersService,
  UtilsService,
  RouteService,
  RoleService
} from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { flags, images } from 'src/images';
import {
  getUserDriverForm,
  getUserRegularForm,
} from 'src/app/forms/userForms';
import { Modal } from 'flowbite';
import { Router, NavigationStart } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';
import { APP } from 'src/app/config/app';
import { ErrorsCode } from 'src/app/models/enums/errorsCode';

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
    standalone: false
})
export class UserDetailsComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @Input() user: any = {} as IUserRegular | {} as IUserDriver;
  @Input() newUser: any;
  @Output() closeDetails = new EventEmitter();
  @Output() userEdited = new EventEmitter<IUserRegular | IUserDriver>();
  @Output() showViewBookingsEventEmitter = new EventEmitter();
  @Output() showDocumentsModalEventEmitter = new EventEmitter();
  @Output() showChangeStatusEventEmitter = new EventEmitter();
  @Output() showFavourites = new EventEmitter();
  public userCopy: any = {} as IUserRegular | {} as IUserDriver;
  public TooltipPosition = TooltipPosition;
  public images = images;
  public userStatus = UserStatus;
  public userType = UserType;
  public userRole = UserRole;
  public expanded = false;
  public selectedClients: IBase[] = [];
  public edit = false;
  public editStatus = false;
  public adminRole: UserRole;
  public adminLanguage: string;

  public shownUserId?: number;

  private routerSubscription: Subscription;

  public Array = Array;
  public InputType = InputType;
  public genders: Gender[] = [];
  public userRoles: UserRole[] = [];
  public userStatusList: any[] = [];
  public languages: unknown[] = [];
  public notifications: unknown[] = [];
  public clients: IClient[] = [];
  public towns: ITown[] = [];
  public operators: IOperator[] = [];
  public notificationsValues: unknown[] = [];

  public flags = flags;

  public policyAcceptedIsChecked = false;

  public userForm: any[];

  @ViewChild('form') form: ElementRef;
  public addToAppModal: Modal;
  public changePasswordModal: Modal;

  public userFormControl: FormGroup;
  public invalid: any[] = [];

  public phoneObject = { prefix: '+34', phone: '' };

  public addToAppShowed: boolean;
  public changePasswordShowed: boolean;

  public password = '';
  public confirmPassword = '';
  public changingPassword = false;

  public apps: IApps[] = [];

  public originalUser = {} as IUserRegular | {} as IUserDriver;

  constructor(
    public literalService: LiteralService,
    public usersService: UsersService,
    public utilsService: UtilsService,
    public notificationService: NotificationService,
    public router: Router,
    public storageService: StorageService,
    private clipboardService: ClipboardService,
    private routeService: RouteService,
    public roleService: RoleService
  ) {}

  async ngOnInit() {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Close the modal when navigating away from the current view
        this.closeModals();
      }
    });
    this.adminLanguage = await this.storageService.getLanguage();
  }

  async ngAfterViewInit() {
    this.adminRole = await this.storageService.getRole();
    if (!this.newUser && this.user) this.shownUserId = this.user.id;
    this.getUserForm();

    this.originalUser = structuredClone(this.user);

    this.initializeModals();
  }

  async ngOnChanges() {
    this.adminRole = await this.storageService.getRole();
    if (this.newUser) {
      this.shownUserId = undefined;
    } else if (this.user && this.shownUserId && this.user.id != this.shownUserId) {
      this.edit = false;
      this.editStatus = false;
      this.shownUserId = this.user.id;

    }
    this.getUserForm();

    this.userStatusList = [];
    this.orderUserStatusDropdown();
    this.apps = [];
    if (this.user?.type === UserType.Regular) {
      const appsArray =
        this.user?.id && (await this.usersService.getUserApps(this.user.id));
      appsArray &&
        appsArray.forEach((app: IApps) => {
          if (!this.apps.find((appIncluded: IApps) => appIncluded.id === app.id)) {
            this.apps.push(app);
          }
        });
    }
    // this.user?.id && this.initializeModals();
  }

  private async getUserForm() {
    this.user && this.user.type === UserType.Regular
    ? getUserRegularForm(
        this.user as IUserRegular,
        this.literalService,
        this.usersService,
        this.storageService
      ).then((res: any) => {
        this.userForm = res.form;
        this.towns = res.towns;
        this.updateTownSelection();
      })
    : getUserDriverForm(
        this.user as IUserDriver,
        this.literalService,
        this.usersService,
        this.storageService
      ).then((res: any) => {
        this.userForm = res.form;
        this.towns = res.towns;
        this.operators = res.operators;
        this.updateTownSelection();
        this.updateOperatorSelection();
      });
  }

  private updateTownSelection() {
    const townIndex = this.towns.findIndex((town: ITown) => town.id === this.user?.town?.id);
    if (this.user.town === undefined || townIndex === -1) {
      this.user.town = this.towns.at(0);
    }
  }

  private updateOperatorSelection() {
    const operatorIndex = this.operators.findIndex((operator: IOperator) => operator.id === this.user.operator?.id);
    if (this.user.operator === undefined || operatorIndex === -1) {
      this.user.operator = this.operators.at(0);
    }
  }

  private async initializeModals() {
    this.changePasswordModal = new Modal(document.getElementById('changePasswordModal'), {
        placement: 'center',
        closable: true,
        onHide: () => {
          this.changePasswordShowed = false;
          document.getElementById('changePassword')!.style.zIndex = '0';
        },
        onShow: () => {
          this.changePasswordShowed = true;
        },
      },
    );

    this.addToAppModal = new Modal(document.getElementById('addToAppModal'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        this.addToAppShowed = false;
        document.getElementById('addToApp')!.style.zIndex = '0';
      },
      onShow: () => {
        this.addToAppShowed = true;
      },
    });
  }

  closeModals() {
    if (this.changePasswordShowed) this.changePasswordModal.toggle();
    if (this.addToAppShowed) this.addToAppModal.toggle();
  }

  editStatusClick() {
    this.editStatus = !this.editStatus;
  }

  async changeStatus(data: any) {
    if (data) this.showChangeStatusEventEmitter.emit(data.value);
    this.editStatus = false;
  }

  editAction() {
    this.edit = !this.edit;
    this.userCopy = {
      ...this.user,
      clients: {...this.user.clients},
      sms: null,
      sendEmail: null,
      push: null
    };
    this.phoneObject = { prefix: '+34', phone: '' };
  }

  onInputchange(event: any, element: any) {
    if (Array.isArray(element.edit?.valueToChange) || element.edit?.valueToChange === 'clients') {
      this.changeData(event.target, element);
    } else {
      this.user[element.edit?.valueToChange] = this.changeData(event.target, element);
      if (element.edit.valueToChange === 'phone' || element.edit.valueToChange === 'email') this.getUserForm();
    }
  }

  changeData(data: any, element?: any) {
    if (element?.edit.valueToChange === 'clients') {
      this.ngOnChanges();
    } else if (element?.edit.valueToChange === 'town') {
      return this.towns.find((town: ITown) => data.value == town.id);
    } else if (element?.edit.valueToChange === 'operator') {
      return this.operators.find((operator: IOperator) => Number(data.value) === operator.id);
    } else if (element?.edit.valueToChange === 'phone') {
      return this.setPhoneData(data, this.user.phone);
    } else if (element?.edit.valueToChange === 'additionalPhone') {
      return this.setPhoneData(data, this.user.additionalPhone);
    } else if (Array.isArray(element?.edit.valueToChange)) {
      this.setMultiSelectData(data, element);
    }
    return this.setNormalData(data);
  }

  private setPhoneData(data: any, phone: any) {
    if (!phone) {
      if (data.tagName === 'INPUT') {
        this.phoneObject.phone = data.value;
      } else if (data.tagName === 'SELECT') {
        this.phoneObject.prefix = data.value;
      }
    } else {
      const prefixRegEx =
        /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
      this.phoneObject.prefix = `+${phone.match(prefixRegEx)[1]}`;
      this.phoneObject.phone = phone;
      this.phoneObject.phone = this.phoneObject.phone.replace(
        this.phoneObject.prefix,
        '',
      );
      if (data.tagName === 'INPUT') {
        this.phoneObject.phone = data.value;
      } else if (data.tagName === 'SELECT') {
        this.phoneObject.prefix = data.value;
      }
    }
    return this.phoneObject.phone !== '' ? this.phoneObject.prefix + this.phoneObject.phone : '';
  }

  private setNormalData(data: any) {
    if (this.isValidDate(data.value)) {
      return moment(data.value).format('yyyy-MM-DD');
    }
    return data.value;
  }

  public getPenalizedEndDate(user: IUserRegular) {
    let response = '';
    response = user.penaltyEndDate ? `<strong>${this.literalService.get('users.penalizedUntil', true)}</strong><br>${moment(user.penaltyEndDate).format('DD/MM/YYYY')}` : this.literalService.get('users.actions.Blocked.name', true);
    response += `<br><br><strong>${this.literalService.get('users.actions.Blocked.reasonTitle', true)}:</strong><br>${user.penaltyReason ? user.penaltyReason : this.literalService.get('notSpecified', true)}`;
    return response;
  }

  public showDistanceTravelled(user: IUserRegular) {
    return `<strong>${this.literalService.get('users.actions.' + user.status + '.name', true)}</strong><br/>${this.literalService.get('users.data.distanceTravelled', true)}: ${user.distanceTravelled!.toFixed(2)}`;
  }

  private setMultiSelectData(data: any, element: any) {
    element.edit.valueToChange.map((value: string) => {
      let checked = false;
      if (element.edit?.selectedValues.some((selectedValue: any) => selectedValue.id === value)) {
        checked = true; 
      }
      this.user[value] = checked;
    })
  }

  public isValidDate(d: any) {
    return d instanceof Date && !isNaN(Number(d));
  }

  public showAddToApp() {
    document.getElementById('addToApp')!.style.zIndex = '50';
    this.addToAppModal.toggle();
  }

  public showChangePassword() {
    document.getElementById('changePassword')!.style.zIndex = '50';
    this.changePasswordModal.toggle();
  }

  public close() {
    this.expanded = false;
    this.edit = false;
    this.editStatus = false;
    this.newUser = false;
    this.closeDetails.emit();
  }

  public initFormControl() {
    this.userFormControl = new FormGroup({
      firstName: new FormControl(this.user.firstName, [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      clients: new FormControl(this.user.clients, [Validators.required]),
    });
    this.user.type === UserType.Driver && this.userFormControl.addControl('operator', new FormControl(this.user.operator, [Validators.required]));
    this.user.type === UserType.Driver && this.userFormControl.addControl('username', new FormControl(this.user.username, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]));
    if (this.user.type == UserType.Regular) {
      !this.user.phone &&
        this.userFormControl.addControl(
          'email',
          new FormControl(this.user.email, [
            Validators.required,
            Validators.email,
            Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
          ]),
        );
      !this.user.email &&
        this.userFormControl.addControl(
          'phone',
          new FormControl(this.user.phone, [
            Validators.required,
            Validators.pattern(/^\+(?:\d{1,3})?(?:[ -]?\d{1,4}){1,14}$/),
          ]),
        );
      }
  }

  public async findInvalidControls() {
    this.initFormControl();

    this.invalid = [];
    const controls = this.userFormControl.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalid.push(name);
      }
    }
  }

  showError(value: string, field: string) {
    let errorText = '';
    this.invalid.map((invalidField: string) => {
      if (invalidField === field) {
        if (!this.user[invalidField] || this.user[invalidField].length === 0) {
          if (invalidField === "email" || invalidField === "phone") errorText = this.literalService.get('requiredFieldOrNext', true);
          else errorText = this.literalService.get('errors.requiredField', true);
        } else {
          errorText = this.literalService.get('errors.incorrectField', true);
        }
      }
    });
    return errorText;
  }

  private resetUserInfo() {
    this.edit = false;
    this.newUser = false;
    this.userEdited.emit(this.user);
    this.originalUser = structuredClone(this.user);
    this.ngOnChanges();
  }

  async onSubmit() {
    await this.findInvalidControls();
    if (this.invalid.length === 0) {
      const formattedDate = moment(this.user.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      let data = { id: this.user.id, type: this.user.type } as any;
    
      if (this.edit) {
        const diffData = this.utilsService.compareObjects(this.userCopy, this.user);
        diffData.forEach((field: any) => (data[field] = this.user[field]));
        if (data.phone && data.phone.length < 4) data.phone = '';
        if (data.additionalPhone && data.additionalPhone.length < 4) data.additionalPhone = '';
        if (data.type === UserType.Regular && data.birthDate) data.birthDate = formattedDate;
        this.usersService.editUser(data).then((res) => {
          this.user = res;
          this.resetUserInfo();
        })
        .catch((error) => {
          if (error.message.includes(`${ErrorsCode.ENTITY_ALREADY_EXISTS}`)) {
            this.notificationService.image = images.error;
            this.notificationService.title = this.literalService.get(`errorOccurred`, true);
            this.notificationService.message = this.literalService.get(`users.actions.edit.duplicatedError`, true);
            this.notificationService.show(NotificationTypes.DANGER);
          }
        })
      } else {
        if (this.user.type === UserType.Regular && this.user.birthDate) this.user.birthDate = formattedDate;
        this.usersService.createUser(this.user).then((res) => {
          this.user = res;
          this.resetUserInfo();
        })
        .catch((error) => {
          if (error.message.includes(`${ErrorsCode.ENTITY_ALREADY_EXISTS}`)) {
            this.notificationService.image = images.error;
            this.notificationService.title = this.literalService.get(`errorOccurred`, true);
            this.notificationService.message = this.literalService.get(`users.actions.edit.duplicatedError`, true);
            this.notificationService.show(NotificationTypes.DANGER);
          }
        })
      }
    
      const actionType = this.edit ? 'edit' : 'create';
      this.utilsService.showNotification(images.sidebar.users, `users.actions.${actionType}`, NotificationTypes.SUCCESS);

      // TODO this should be better done redirecting to the details and going through ngOnChanges
      // TODO need to also set apps for the apps modal to not be empty
      this.initializeModals();
    }
  }

  public isExpandedFormInvalid(invalidArray: string[]) {
    return this.userForm && this.userForm.some(form => form.expanded && form.edit && form.edit.valueToChange && invalidArray.includes(form.edit.valueToChange));
  }

  public async changePassword(password: string) {
    this.changingPassword = true;
    await this.usersService.changePassword(this.user.id, password).then(() => {
      this.utilsService.showNotification(images.user.password, 'users.actions.changePassword.passwordChangedSuccess', NotificationTypes.SUCCESS);
    }, () => {
      this.utilsService.showNotification(images.user.password, 'users.actions.changePassword.passwordChangedError', NotificationTypes.DANGER);
    });
    this.changePasswordModal.toggle();
    this.changingPassword = false;
  }

  public async addToApp(event: number[]) {
    await this.usersService.addToApp(this.user.id, event);
    this.utilsService.showNotification(images.confirm, 'users.actions.addedToApp', NotificationTypes.SUCCESS, 'successTitle', 'successText');
    this.addToAppModal.toggle();
  }

  private orderUserStatusDropdown() {
    if (this.user && this.user.status && this.user.type && this.adminRole && this.user.status.toUpperCase() !== UserStatus.DELETED) {
      this.userStatusList.push({ id: UserStatus.ACTIVE, name: UserStatus.ACTIVE.toUpperCase() });
      this.userStatusList.push({ id: UserStatus.INACTIVE, name: UserStatus.INACTIVE.toUpperCase() });
      if (this.user.type === UserType.Regular) {
        this.userStatusList.push({ id: UserStatus.BLOCKED, name: UserStatus.BLOCKED.toUpperCase() });
      }
      if (this.user.status.toUpperCase() == UserStatus.INACTIVE && (this.adminRole === UserRole.SUPERADMIN || this.adminRole === UserRole.ADMIN)) {
        this.userStatusList.push({ id: UserStatus.DELETED, name: UserStatus.DELETED.toUpperCase() });
      }
    }
  }

  goToSelfBookings() {
    this.storageService.setUserSelected(this.user.id);
    this.router.navigate(['/bookings']);
  }

  copyLink(userId: number): void {
    const domain = this.routeService.getCurrentDomain();
    this.clipboardService.copyFromContent(domain + '/users/' + userId);
  }

  disabledButton() {
    if (this.edit) return !(this.utilsService.compareObjects(this.user, this.originalUser).length > 0);
    if (this.userForm) {
      this.initFormControl();
      return !this.userFormControl.valid;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  getClientByTown() {
    if (this.user.town) {
      switch(this.user.town.id) {
        case 119772:  // Cervelló
          return "AMB";
        case 25805249:  // Sierra Norte de Madrid
        case 34543220:  // Nueva Zona SierraCar
          return "SierraCar";
        case 30930319: // La Segarra
          return "Cotsalsina";
        case 25004924:  // Vilafranca del Penedès
          return "Pnds";
        case 39558188:  // Vitoria
          return "Tuvisa";
        case 117576096: // Palencia: Baltanás
        case 108424773: // Palencia: Cervera de Pisuerga
        case 117623384: // Palencia: Saldaña
          return "Dipucar";
        default:
          return "Nemi";
      }
    } else {
      return "Nemi";
    }
  }

  getPrivacyLink() {
    const client = this.getClientByTown();
    const privacyOption = APP.appsPrivacyAndTermsOptions.find(option => option.name === client);
    return `${ privacyOption?.privacy }${privacyOption?.someLanguages ? this.adminLanguage : ''}`
  }

  getTermsLink() {
    const client = this.getClientByTown();
    const termsOptions = APP.appsPrivacyAndTermsOptions.find(option => option.name === client);
    return `${ termsOptions?.terms }${termsOptions?.someLanguages ? this.adminLanguage : ''}`
  }

  onCancel(event: Event) {
    if (this.newUser === undefined) {
      event.stopPropagation();
      this.editAction();
    }
    else this.close();
  }
}

