import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Modal } from 'flowbite';
import { Subscription } from 'rxjs';
import { BookingOrigin, ExpeditionStatus, InputType } from 'src/app/models/enums';
import { BookingsService, TripsService } from 'src/app/services';
import { LanguageService } from 'src/app/services/language/language.service';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { images } from 'src/images';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ITrip } from 'src/app/models';

@Component({
    selector: 'app-modal-view-bookings',
    templateUrl: './modal-view-bookings.component.html',
    styleUrl: './modal-view-bookings.component.scss',
    standalone: false
})
export class ModalViewBookingsComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() text: string;
  @Input() values?: any[];
  @Input() bookings: IBookingDetails[];
  @Input() showDate: boolean;
  @Input() userId: number;
  @Input() trip: ITrip;
  @Output() deleteBookingEventEmitter = new EventEmitter<number>();
  @Output() validatePickUpEventEmitter = new EventEmitter<any>();
  @Output() validateDropOffEventEmitter = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;

  public buttonDisabled = false;
  public selectedBooking: IBookingDetails | undefined;

  public language: string = 'es';
  private languageSubscription: Subscription;
  public TooltipPosition = TooltipPosition;

  public ExpeditionStatus = ExpeditionStatus;
  public BookingOrigin = BookingOrigin;

  @ViewChild('bookingsList') bookingsList: ElementRef;

  constructor(
    public literalService: LiteralService,
    public tripsService: TripsService,
    public languageService: LanguageService,
    public utilsService: UtilsService,
    public router: Router,
    public appComponent: AppComponent,
    public bookingsService: BookingsService
  ) {}

  ngOnInit(): void {
    this.languageSubscription = this.languageService.language$.subscribe(
      (language: string) => {
        this.language = language;
      },
    );
  }

  goToNewBooking(): void {
    let queryParams = {};
    if (this.userId) queryParams = { 'userId': this.userId };
    if (this.trip) queryParams = { ...queryParams, 'dateTime': this.trip.dateTime }
    this.appComponent.setRouteName('bookings');
    this.router.navigate(
      ['/bookings/new'],
      { queryParams }
    );
  }

  deleteBooking(): void {
    this.deleteBookingEventEmitter.emit(this.selectedBooking?.id);
  }

  goToSelectedBooking(bookingId: number): void {
    this.appComponent.setRouteName('bookings');
    this.router.navigate(['/bookings', bookingId]);
  }

  getTooltipText(type: BookingOrigin, notified?: boolean, userPhone?: string) {
    return this.bookingsService.getChannelTooltipText(type, notified, userPhone);
  }

  selectBooking(booking: IBookingDetails): void {
    if (this.selectedBooking?.id === booking.id) this.selectedBooking = undefined;
    else this.selectedBooking = booking;
  }

  onModalClick(event: any) {
    const formElement = this.bookingsList.nativeElement as HTMLElement;
    if (!formElement?.contains(event.target)) {
      this.selectedBooking = undefined;
    } 
    event.stopPropagation();
  }
  
  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }
}
