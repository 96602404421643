import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    standalone: false
})
export class CheckboxComponent {
  @Input() id: number;
  @Input() text: string;
  @Input() value: any;
  @Input() checked: boolean;
}
