export const environment = {
	production: true,
	cacheTime: 0,
	apiUrl: 'https://sta.nemi.mobi/backoffice/private/v2',
	authUrl: 'https://sta.nemi.mobi/backoffice',
	googleMapsApiKey: 'AIzaSyBn-OS9U-YRm0100VZI0FtVGV104JGhHU4',
	mapId: '7e19c92872f69759',
	location: 'sta'
};

export const firebaseConfig = {
	apiKey: 'AIzaSyBEdg9VK9CbTpAQC2e3oT38AyQ6FsNoTPs',
	authDomain: 'deft-ellipse-236414.firebaseapp.com',
	databaseURL: 'https://deft-ellipse-236414.firebaseio.com',
	projectId: 'deft-ellipse-236414',
	storageBucket: 'deft-ellipse-236414.appspot.com',
	messagingSenderId: '199162947909',
	appId: '1:199162947909:web:05e1b04045b158e919762c',
	measurementId: 'G-QY82Z76XCS',
};
