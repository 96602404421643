import { Component, EventEmitter, Output, input } from '@angular/core';
import { IServiceInfoDetail } from 'src/app/models';
import { images } from 'src/images';

@Component({
    selector: 'app-service-row',
    templateUrl: './service-row.component.html',
    styleUrl: './service-row.component.scss',
    standalone: false
})
export class ServiceRowComponent {

  readonly service = input<IServiceInfoDetail>();
  readonly serviceInfoSelected = input<any>();
  // @Input() showRoutesService: IServiceInfoDetail;
  @Output() showRoutes = new EventEmitter<any>();
  @Output() showServiceInfoData = new EventEmitter<any>();

  public images = images;
}
