import { flags, images } from '../../images';
import { IClient, ITown, IUserDriver, IUserRegular } from '../models';
import {
  Gender,
  InputType,
  UserRole,
  UserStatus,
  UserType,
} from '../models/enums';
import { StorageService, UsersService } from '../services';
import { LiteralService } from '../services/literal/literal.service';
import { TooltipPosition } from '../shared/tooltip/tooltip-position';

export function getNotificationsValues(user?: IUserRegular): unknown[] {
  if (user) {
    const data = [];
    user.sms && data.push({ id: 'sms', name: 'SMS', code: 'SMS' });
    user.sendEmail &&
      data.push({ id: 'sendEmail', name: 'EMAIL', code: 'EMAIL' });
    user.push && data.push({ id: 'push', name: 'PUSH', code: 'PUSH' });
    return data;
  }
  return [
    { id: 'sms', name: 'SMS', code: 'SMS' },
    { id: 'sendEmail', name: 'EMAIL', code: 'EMAIL' },
    { id: 'push', name: 'PUSH', code: 'PUSH' },
  ];
}

export async function getClientsTowns(usersService: UsersService) {
  return await usersService.getPossibleClientTowns().then((res) => {
    const clients: any[] = [];
    const towns: any[] = [];
    res.clients.map((client: IClient) => {
      clients.push({ id: client.id, name: client.name, code: client.code });
    });
    res.towns.map((town: ITown) => {
      towns.push({ id: town.id, name: town.name });
    });
    return { clients: clients, towns: towns };
  });
}

export async function getUserRegularForm(
  user: IUserRegular = {} as IUserRegular,
  literalService: LiteralService,
  usersService: UsersService,
  storageService: StorageService
): Promise<unknown[]> {
  const genders: unknown[] = [];
  const userRoles: unknown[] = [];
  const languages: unknown[] = [];
  let clients: unknown[] = [];
  let towns: unknown[] = [];
  let selectedUserIds: any[] = [];
  let notifications: unknown[] = [];
  let prmValues: unknown[] = [];
  let prmValue: unknown[] = [];
  const adminRole = await storageService.getRole();
  flags.map((flag: any) => {
    languages.push({
      id: flag.name,
      name: literalService.get(`languages.${flag.name.toLowerCase()}`, true),
    });
  });

  (Object.keys(Gender) as Array<keyof typeof Gender>).map(
    (gender: keyof typeof Gender) => {
      genders.push({
        id: gender,
        name: literalService.get(`users.genders.${gender}`, true),
      });
    },
  );

  userRoles.push({id: UserRole.USER, name: literalService.get(`users.roles.${UserRole.USER.toLowerCase()}`, true)});
  if (adminRole === UserRole.SUPERADMIN) {
    userRoles.push({id: UserRole.ADMIN, name: literalService.get(`users.roles.${UserRole.ADMIN.toLowerCase()}`, true)});
  }
  if (adminRole === UserRole.SUPERADMIN || adminRole === UserRole.ADMIN) {
    userRoles.push({id: UserRole.OPERATOR, name: literalService.get(`users.roles.${UserRole.OPERATOR.toLowerCase()}`, true)});
    //userRoles.push({id: UserRole.DRIVER_MANAGER, name: literalService.get(`users.roles.${UserRole.DRIVER_MANAGER.toLowerCase()}`, true)})
  }

  await getClientsTowns(usersService).then((res) => {
    clients = res.clients;
    if (clients.length <= 1) towns = res.towns;
  });

  selectedUserIds =
    user.clients?.map((client: IClient) => {
      return client.id;
    }) || [];
  selectedUserIds.length > 0 &&
    (await usersService.getTownsByClient(selectedUserIds).then((res: any) => {
      towns = res;
    }));

  notifications = getNotificationsValues();
  prmValues = user.prm ? [
    { id: 'true', name: literalService.get('yes', true), code: 'yes' },
    { id: 'false', name: literalService.get('no', true), code: 'no' },
  ] : [
    { id: 'false', name: literalService.get('no', true), code: 'no' },
    { id: 'true', name: literalService.get('yes', true), code: 'yes' },
  ];
  prmValue = user.prm ? [{ id: 'true', name: literalService.get('yes', true), code: 'yes' }]
  : [{ id: 'false', name: literalService.get('no', true), code: 'no' }];

  const data = [
    {
      img: images.user.account,
      title: 'users.data.name',
      value: user.firstName,
      array: false,
      required: true,
      hidden: false,
      edit: {
        inputType: InputType.TEXT,
        translateValue: false,
        required: true,
        disabled: false,
        valueToChange: 'firstName',
      },
    },
    {
      img: images.user.account,
      title: 'users.data.lastName',
      value: user.lastName,
      array: false,
      required: false,
      hidden: false,
      edit: {
        inputType: InputType.TEXT,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'lastName',
      },
    },
    {
      img: images.user.mail,
      title: 'users.data.mail',
      value: user.email,
      array: false,
      required: user.phone ? false : true,
      hidden: false,
      edit: {
        inputType: InputType.EMAIL,
        translateValue: false,
        required: user.phone ? false : true,
        disabled: false,
        valueToChange: 'email',
      },
    },
    {
      img: images.user.phone,
      title: 'users.data.phone',
      value: user.phone,
      array: false,
      required: user.email ? false : true,
      hidden: false,
      edit: {
        inputType: InputType.PHONE,
        translateValue: false,
        required: user.email ? false : true,
        disabled: false,
        valueToChange: 'phone',
      },
    },
    {
      img: images.user.id,
      title: 'users.data.dni',
      value: user.dni,
      array: false,
      required: false,
      hidden: false,
      edit: {
        inputType: InputType.TEXT,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'dni',
      },
    },
    {
      img: images.user.area,
      title: 'users.data.area',
      value: user.town?.name,
      array: false,
      required: true,
      hidden: false,
      edit: {
        id: user.town?.id,
        inputType: InputType.SELECT,
        translateValue: false,
        required: true,
        disabled: false,
        values: towns,
        valueToChange: 'town',
      },
    },
    {
      img: images.user.role,
      title: 'users.data.role',
      value: user.role && user.role.toLowerCase(),
      translateValue: true,
      array: false,
      required: true,
      expanded: false,
      hidden: adminRole === UserRole.OPERATOR,
      edit: {
        id: user.role,
        inputType: InputType.SELECT,
        translateValue: true,
        required: true,
        disabled: false,
        values: userRoles,
        valueToChange: 'role',
      },
    },
    {
      img: images.prm,
      title: 'users.data.prm',
      value: prmValue,
      array: true,
      required: false,
      expanded: true,
      hidden: false,
      edit: {
        inputType: InputType.SELECT,
        translateValue: false,
        required: false,
        disabled: false,
        values: prmValues,
        selectedValues: prmValue,
        valueToChange: 'prm',
      },
    },
    {
      img: images.user.gender,
      title: 'users.data.gender',
      value: user.gender && user.gender.toLowerCase(),
      translateValue: true,
      array: false,
      required: false,
      expanded: true,
      hidden: false,
      edit: {
        id: user.gender,
        inputType: InputType.SELECT,
        translateValue: true,
        required: false,
        disabled: false,
        values: genders,
        valueToChange: 'gender',
      },
    },
    {
      img: images.user.additionalPhone,
      title: 'users.data.additionalPhone',
      value: user.additionalPhone,
      array: false,
      required: false,
      expanded: true,
      hidden: false,
      edit: {
        inputType: InputType.PHONE,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'additionalPhone',
      },
    },
    {
      img: images.user.birthDate,
      title: 'users.data.birthDate',
      value: user.birthDate,
      array: false,
      required: false,
      expanded: true,
      hidden: false,
      showYear: true,
      edit: {
        inputType: InputType.DATE,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'birthDate',
      },
    },
    {
      img: images.user.settlement,
      title: 'users.data.settlement',
      value: user.settlement,
      array: false,
      required: false,
      expanded: true,
      hidden: false,
      edit: {
        inputType: InputType.TEXT,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'settlement',
      },
    },
    {
      img: images.user.language,
      title: 'users.data.language',
      value: user.language,
      translateValue: true,
      array: false,
      required: true,
      expanded: true,
      hidden: adminRole === UserRole.OPERATOR,
      edit: {
        id: user.language,
        inputType: InputType.SELECT,
        translateValue: true,
        required: true,
        disabled: false,
        values: languages,
        valueToChange: 'language',
      },
    },
    {
      img: images.user.account,
      title: 'users.data.username',
      value: user.username,
      array: false,
      required: false,
      expanded: true,
      hidden: adminRole === UserRole.OPERATOR,
      edit: {
        inputType: InputType.TEXT,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'username',
      },
    },
    {
      img: images.user.notifications,
      title: 'users.data.notifications',
      value: getNotificationsValues(user),
      array: true,
      required: false,
      expanded: true,
      hidden: false,
      edit: {
        inputType: InputType.MULTISELECT,
        translateValue: false,
        required: false,
        disabled: false,
        values: notifications,
        selectedValues: getNotificationsValues(user),
        valueToChange: ['sms', 'sendEmail', 'push'],
      },
    },
    {
      img: images.user.clients,
      title: 'users.data.clients',
      value: user.clients,
      dataToShow: 'code',
      array: true,
      required: true,
      expanded: true,
      hidden: clients.length <= 1 || adminRole === UserRole.OPERATOR,
      edit: {
        inputType: InputType.MULTISELECT,
        translateValue: false,
        required: true,
        disabled: false,
        values: clients,
        selectedValues: clients.length <= 1 ? clients : user.clients,
        valueToChange: 'clients',
      },
    },
    {
      img: images.user.privacyPolicy,
      title: 'users.data.privacyPolicy',
      value:
        user.policyAccepted &&
        ('privacyPolicy.' + user.policyAccepted).toLowerCase(),
      translateValue: true,
      array: false,
      required: false,
      expanded: true,
      hidden: true,
    },
    {
      img: images.user.device,
      title: 'users.data.device',
      value: user.deviceType,
      translateValue: true,
      array: false,
      required: false,
      expanded: true,
      hidden: adminRole !== UserRole.SUPERADMIN,
    },
    {
      img: images.user.token,
      title: 'users.data.token',
      value: user.deviceToken,
      translateValue: true,
      array: false,
      required: false,
      expanded: true,
      hidden: adminRole !== UserRole.SUPERADMIN,
      tooltipPosition: TooltipPosition.LEFT,
    },
    {
      img: images.distance,
      title: 'users.data.distanceTravelled',
      value: user.distanceTravelled,
      translateValue: false,
      array: false,
      required: false,
      expanded: true,
      hidden: adminRole === UserRole.SUPERADMIN,
    },
  ];

  /*if (!newUser) {
    data.push(
      {
        img: images.user.device,
        title: 'users.data.device',
        value: user.deviceType,
        array: false,
        required: false,
        expanded: true,
        hidden: false,
        edit: {
          inputType: InputType.TEXT,
          translateValue: false,
          required: false,
          disabled: true,
          valueToChange: 'device',
        },
      },
      {
        img: images.user.token,
        title: 'users.data.token',
        value: user.deviceToken,
        array: false,
        required: false,
        expanded: true,
        hidden: false,
        edit: {
          inputType: InputType.TEXT,
          translateValue: false,
          required: false,
          disabled: true,
          valueToChange: 'token',
        },
      },

    );
  }*/

  return data;
}

export async function getUserDriverForm(
  user: IUserDriver = {} as IUserDriver,
  literalService: LiteralService,
  usersService: UsersService,
  storageService: StorageService
  //showClients: boolean
): Promise<unknown[]> {
  const userRoles: unknown[] = [];
  const languages: unknown[] = [];
  let operators: unknown[] = [];
  let clients: any[] = [];
  let towns: unknown[] = [];
  let selectedUserIds: any[] = [];
  const adminRole = await storageService.getRole();

  flags.map((flag: any) => {
    languages.push({
      id: flag.name,
      name: literalService.get(`languages.${flag.name.toLowerCase()}`, true),
    });
  });
  
  userRoles.push({id: UserRole.USER, name: literalService.get(`users.roles.${UserRole.USER.toLowerCase()}`, true)});
  if (adminRole === 'SUPERADMIN' || adminRole === 'ADMIN') {
    userRoles.push({id: UserRole.DRIVER_MANAGER, name: literalService.get(`users.roles.${UserRole.DRIVER_MANAGER.toLowerCase()}`, true)});
  }

  await getClientsTowns(usersService).then((res) => {
    clients = res.clients;
    if (clients.length === 1) {
      towns = res.towns;
      const resClientIds :number = clients[0].id;
      if (resClientIds) {
        usersService.getOperatorsByClient([resClientIds])
        .then((res: any) => {
          operators = res;
          if (!user.operator || !res.find(user.operator)) {
            user.operator = res[0];
          }
        });
      }
    }
  });

  selectedUserIds =
    user.clients?.map((client: IClient) => {
      return client.id;
    }) || [];
  selectedUserIds.length > 0 &&
    (await usersService.getOperatorsByClient(selectedUserIds).then((res: any) => {
      operators = res;
    }));
    selectedUserIds.length > 0 &&
    (await usersService.getTownsByClient(selectedUserIds).then((res: any) => {
      towns = res;
    }));

  return [
    {
      img: images.user.account,
      title: 'users.data.name',
      value: user.firstName,
      array: false,
      required: true,
      hidden: false,
      edit: {
        inputType: InputType.TEXT,
        translateValue: false,
        required: true,
        disabled: false,
        valueToChange: 'firstName',
      },
    },
    {
      img: images.user.account,
      title: 'users.data.lastName',
      value: user.lastName,
      array: false,
      required: false,
      hidden: false,
      edit: {
        inputType: InputType.TEXT,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'lastName',
      },
    },
    {
      img: images.user.mail,
      title: 'users.data.mail',
      value: user.email,
      array: false,
      required: false,
      hidden: false,
      edit: {
        inputType: InputType.EMAIL,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'email',
      },
    },
    {
      img: images.user.phone,
      title: 'users.data.phone',
      value: user.phone,
      array: false,
      required: false,
      hidden: false,
      edit: {
        inputType: InputType.PHONE,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'phone',
      },
    },
    {
      img: images.user.account,
      title: 'users.data.username',
      value: user.username,
      array: false,
      required: true,
      hidden: false,
      edit: {
        inputType: InputType.TEXT,
        translateValue: false,
        required: true,
        disabled: false,
        valueToChange: 'username',
      },
    },
    {
      img: images.user.area,
      title: 'users.data.area',
      value: user.town?.name,
      array: false,
      required: true,
      hidden: true,
      edit: {
        id: user.town?.id,
        inputType: InputType.SELECT,
        translateValue: false,
        required: true,
        disabled: user.clients?.length === 0 ? true : false,
        values: towns,
        valueToChange: 'town',
      },
    },
    {
      img: images.user.language,
      title: 'users.data.language',
      value: user.language,
      translateValue: true,
      array: false,
      required: true,
      expanded: true,
      hidden: adminRole === UserRole.DRIVER_MANAGER,
      edit: {
        id: user.language,
        inputType: InputType.SELECT,
        translateValue: true,
        required: true,
        disabled: false,
        values: languages,
        valueToChange: 'language',
      },
    },
    {
      img: images.bus,
      title: 'users.data.operator',
      value: user.operator?.name,
      dataToShow: 'name',
      array: false,
      required: true,
      expanded: true,
      hidden: adminRole === UserRole.DRIVER_MANAGER,
      edit: {
        id: user.operator?.id,
        inputType: InputType.SELECT,
        translateValue: false,
        required: true,
        disabled: false,
        values: operators,
        valueToChange: 'operator',
      },
    },
    {
      img: images.user.role,
      title: 'users.data.role',
      value: user.role && user.role.toLowerCase(),
      translateValue: true,
      array: false,
      required: true,
      expanded: true,
      hidden: adminRole === UserRole.DRIVER_MANAGER,
      edit: {
        id: user.role,
        inputType: InputType.SELECT,
        translateValue: true,
        required: true,
        disabled: false,
        values: userRoles,
        valueToChange: 'role',
      },
    },
    {
      img: images.user.additionalPhone,
      title: 'users.data.additionalPhone',
      value: user.additionalPhone,
      array: false,
      required: false,
      expanded: true,
      hidden: adminRole === UserRole.DRIVER_MANAGER,
      edit: {
        inputType: InputType.PHONE,
        translateValue: false,
        required: false,
        disabled: false,
        valueToChange: 'additionalPhone',
      },
    },
    {
      img: images.user.clients,
      title: 'users.data.clients',
      value: user.clients,
      dataToShow: 'code',
      array: true,
      required: true,
      expanded: true,
      hidden: clients.length <= 1 || adminRole === UserRole.DRIVER_MANAGER,
      edit: {
        inputType: InputType.MULTISELECT,
        translateValue: false,
        required: true,
        disabled: false,
        values: clients,
        selectedValues: clients.length <= 1 ? clients : user.clients,
        valueToChange: 'clients',
      },
    },
  ];
}

export function resetForm(
  user: any = ({} as IUserDriver) || ({} as IUserRegular),
): IUserDriver | IUserRegular {
  user.name = '';
  user.firstName = '';
  user.lastName = '';
  user.email = '';
  user.language = '';
  user.phone = '';
  user.additionalPhone = '';
  user.clients = [];
  user.town = undefined;
  user.role = UserRole.USER;
  user.status = UserStatus.ACTIVE;
  if (user.userType === UserType.Driver) {
    user.type = UserType.Driver;
    user.licenseNumber = '';
    user.operator = undefined;
  } else {
    user.type = UserType.Regular;
    user.dni = '';
    user.sms = false;
    user.push = false;
    user.sendEmail = false;
    user.deviceType = undefined;
    user.deviceToken = '';
    user.settlement = '';
    user.policyAccepted = false;
    user.birthDate = undefined;
    user.gender = Gender.PREFER_NOT_TO_SAY;
  }
  return user;
}
