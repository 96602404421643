<div [id]="id" tabindex="-1" class="absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-1/3 max-h-full p-8 rounded-lg primary-purple-light-2-background overflow-auto">
        <div class="relative rounded-lg shadow dark:bg-gray-700">
            <header class="flex flex-row justify-between gap-4">
                <div class="text-base font-bold primary-purple-text">{{ literalService.get(text, true) }}</div>

                <div class="w-6 h-6 min-w-6">
                    <button (click)="closeModal()" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="addToAppModal">
                        <img [src]="images.close" class="h-6 w-6" />
                    </button>
                </div>
            </header>
            <div class="py-10">
                @for (value of values; track $index) {
                    <app-checkbox [id]="value.id" [value]="value" [checked]="isOptionChecked(value.id)" (change)="onClickCheckbox(value.id)" />
                }
            </div>
            <footer class="flex flex-row justify-between gap-4">
                <button data-modal-hide="addToAppModal" type="button" class="secondary gap-4 py-1 px-6 border-2 font-semibold h-11 w-full uppercase cursor-pointer" (click)="closeModal()">{{ literalService.get('cancel', true) }}</button>
                <button data-modal-hide="addToAppModal" type="button" class="primary gap-4 py-1 px-6 border-2 font-semibold h-11 w-full uppercase cursor-pointer" [disabled]="selectedValues.length === 0" (click)="action.emit(selectedValues)">{{ literalService.get('select', true) }}</button>
            </footer>
        </div>
    </div>
</div>