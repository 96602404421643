import { Injectable } from '@angular/core';
import { RouteHttpService } from './route.http.service';
import { objectToCamel } from 'ts-case-convert';
import { lastValueFrom } from 'rxjs';
import { IRouteDetails, IServiceInfoDetail } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  constructor(private routeHttpService: RouteHttpService) { }

  async getAvailabilities(serviceId: number, page?: number, size?: number, availabilityId?: number) {
    const source$ = this.routeHttpService.getAvailablities(serviceId, page, size, availabilityId);
    const response = await lastValueFrom(source$);
    const availabilities = objectToCamel(response as object);
    const result = this.getData(availabilities);
    return result;
  }
  
  async getDestinationAvailabilityStops(availabilityId: number, inStopId: number, page: number, size: number) {
    const source$ = this.routeHttpService.getAvailablityStops(availabilityId, page, size, inStopId);
    const response = await lastValueFrom(source$);
    const stops = objectToCamel(response as object);
    const stopsArray = this.getData(stops);
    return stopsArray;
  }
  
  async getAvailabilityStops(availabilityId: number, page: number, size: number) {
    const source$ = this.routeHttpService.getAvailablityStops(availabilityId, page, size);
    const response = await lastValueFrom(source$);
    const stops = objectToCamel(response as object);
    const stopsArray = this.getData(stops);
    return stopsArray;
  }

  async getServices(page: number, size: number) {
    const source$ = this.routeHttpService.getServices(page, size);
    const response = await lastValueFrom(source$);
    const services = objectToCamel(response as object);
    const result = this.getData(services);
    return result;
  }

  async getService(idService: number) {
    const source$ = this.routeHttpService.getService(idService);
    const response = await lastValueFrom(source$);
    const service = objectToCamel(response as object);
    return service;
  }

  async getServiceConfig(idService: number) {
    const source$ = this.routeHttpService.getServiceConfig(idService);
    const response = await lastValueFrom(source$);
    const serviceConfig = objectToCamel(response as object);
    return serviceConfig;
  }
  
  getCurrentDomain(): string {
    return window.location.origin;
  }

  async importServiceFromGtfs(data: any, clientId: number, townId: number) {
    const source$ = this.routeHttpService.importServiceFromGtfs(data, clientId, townId);
    const response = await lastValueFrom(source$);
    const services = objectToCamel(response as object);
    const result = this.getData(services);
    return result;
  }

  async importServiceFromCsv(data: any, clientId: number, townId?: number, townName?: string) {
    const source$ = this.routeHttpService.importServiceFromCsv(data, clientId, townId, townName);
    const response = await lastValueFrom(source$);
    const services = objectToCamel(response as object);
    const result = this.getData(services);
    return result;
  }

  async downloadGtfs(routeId: number) {
    const source$ = this.routeHttpService.downloadGtfs(routeId);
    const response = await lastValueFrom(source$);
    return response;
  }

  async getRoutesByServiceInfo(serviceIds: number[], page?: number, size?: number) {
    const source$ = this.routeHttpService.getRoutesByServiceInfo(serviceIds, page, size);
    const response = await lastValueFrom(source$);
    const serviceArray = this.getData(response);
    console.log(serviceArray)
    return serviceArray;
  }

  async getTerrains(townsIds?: number[]) {
    const source$ = this.routeHttpService.getTerrains(townsIds);
    const response = await lastValueFrom(source$);
    console.log(response)
    const terrainsArray = objectToCamel(response as []);
    console.log(terrainsArray)
    return terrainsArray;
  }

  async getRelateds(serviceId: number) {
    const source$ = this.routeHttpService.getRelateds(serviceId);
    const response = await lastValueFrom(source$);
    console.log(response)
    const relatedsArray = objectToCamel(response as []);
    console.log(relatedsArray)
    return relatedsArray;
  }

  async getSaes() {
    const source$ = this.routeHttpService.getSaes();
    const response = await lastValueFrom(source$);
    console.log(response)
    const saesArray = objectToCamel(response as []);
    console.log(saesArray)
    return saesArray;
  }

  async updateRoute(route: IRouteDetails) {
    const source$ = this.routeHttpService.updateRoute(route);
    const response = await lastValueFrom(source$);
    console.log(response)
    const responseRoute = objectToCamel(response as []);
    console.log(responseRoute)
    return responseRoute;
  }

  async createRoute(newRoute: IRouteDetails) {
    const source$ = this.routeHttpService.createRoute(newRoute);
    const response = await lastValueFrom(source$);
    console.log(response)
    const responseRoute = objectToCamel(response as []);
    console.log(responseRoute)
    return responseRoute;
  }
  
  getData(response: any) {
    return response.data;
  }
}
