import { Injectable } from '@angular/core';
import { UsersHttpService } from './users.http.service';
import { UserSortType, UserType, UserStatus } from 'src/app/models/enums';
import { objectToCamel, objectToSnake } from 'ts-case-convert';
import { lastValueFrom } from 'rxjs';
import {
  IApps,
  IOperator,
  ITown,
  IUser,
  IUserDriver,
  IVehicle,
} from 'src/app/models';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { UtilsService } from '../utils/utils.service';
import { IDocumentCreate } from 'src/app/models/documents/documentCreate';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private usersHttpService: UsersHttpService, private utilsService: UtilsService) {}

  async createUser(user: IUser) {
    const source$ = this.usersHttpService.createUser(user);
    const response = await lastValueFrom(source$);
    const userCreated = objectToCamel(response as object);
    return userCreated;
  }

  async editUser(user: IUser) {
    console.log('editUser', user);
    user = objectToSnake(user);
    const source$ = this.usersHttpService.editUser(user);
    const response = await lastValueFrom(source$);
    const userEdited = objectToCamel(response as object);
    return userEdited;
  }

  async getUser(userType: UserType, userId: number) {
    const source$ = this.usersHttpService.getUser(userType, userId);
    const response = await lastValueFrom(source$);
    console.log(response);
    const user = objectToCamel(response as object);
    console.log(user);
    return user;
  }
  
  async getDocumentDownload(userId: number, documentId: number): Promise<void> {
    try {
      const response: any = await lastValueFrom(
        this.usersHttpService.getDocumentDownload(userId, documentId)
      );
      const blob: Blob | null = response.body;
      const contentDisposition: string | null = response.headers.get('Content-Disposition');
      if (!blob) {
        throw new Error('No file content received');
      }
  
      const filename: string = this.extractFilename(contentDisposition) || `document_${documentId}`;
      const url: string = window.URL.createObjectURL(blob);
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
      throw error;
    }
  }
  
  private extractFilename(contentDisposition: string | null): string | null {
    if (!contentDisposition) return null;
  
    const filenameRegex: RegExp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches: RegExpExecArray | null = filenameRegex.exec(contentDisposition);
    
    if (matches && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  
    return null;
  }
  
  private downloadFile(blob: Blob, filename: string): void {
    const url: string = window.URL.createObjectURL(blob);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  async getUsers(userSortType: UserSortType, page?: number, size?: number, searchValue?: string, type?: UserType) {
    const source$ = this.usersHttpService.filter(page, size, userSortType, [], [], type ? [type] : [], [], searchValue);
    const response = await lastValueFrom(source$);
    const users = objectToCamel(response as object);
    const usersArray = this.getData(users);
    return usersArray;
  }

  async filter(
    userSortType: UserSortType,
    page: number,
    size: number,
    clientsSelected: number[], 
    townsSelected: number[], 
    typesSelected: UserType[], 
    statusSelected: UserStatus[], 
    searchValue?: string
  ) {
    const source$ = this.usersHttpService.filter(
      page,
      size,
      userSortType,
      clientsSelected,
      townsSelected,
      typesSelected,
      statusSelected,
      searchValue
    );
    const response = await lastValueFrom(source$);
    const users = objectToCamel(response as object);
    const usersArray = this.getData(users);
    return usersArray;
  }

  async getPossibleClientTowns() {
    const source$ = this.usersHttpService.getPossibleClientTowns();
    const response: any = {};
    await lastValueFrom(source$).then((res: any) => {
      response['clients'] = res.clients;
      response['towns'] = res.towns.filter((town: ITown) => town.active);
      response['towns'].sort(this.utilsService.compare('name'));
    });
    return response;
  }

  async getOperators() {
    const source$ = this.usersHttpService.getOperators();
    const response = await lastValueFrom(source$);
    let operators = objectToCamel(response as IOperator[]);
    operators = operators.filter((operator: IOperator) => operator.active);
    return operators;
  }
  
  async getLoggedUserTown() {
    const source$ = this.usersHttpService.getLoggedUserTown();
    const response = await lastValueFrom(source$);
    const town = objectToCamel(response as ITown);
    return town;
  }

  async getVehicles(tripId: number) {
    const source$ = this.usersHttpService.getVehicles(tripId);
    const response = await lastValueFrom(source$);
    const vehicles = objectToCamel(response as IVehicle[]);
    return vehicles;
  }

  async getDrivers(tripId: number) {
    const source$ = this.usersHttpService.getDrivers(tripId);
    const response = await lastValueFrom(source$);
    const drivers = objectToCamel(response as IUserDriver[]);
    return drivers;
  }

  async getTownsByClient(clients: number[]) {
    const source$ = this.usersHttpService.getTownsByClient(clients);
    const response = await lastValueFrom(source$);
    const towns = objectToCamel(response as object);
    return towns;
  }

  async getOperatorsByClient(clients: number[]) {
    const source$ = this.usersHttpService.getOperatorsByClient(clients);
    const response = await lastValueFrom(source$);
    const operators = objectToCamel(response as object);
    return operators;
  }

  async getTownsByUser(userId: number) {
    const source$ = this.usersHttpService.getTownsByUser(userId);
    const response = await lastValueFrom(source$);
    const towns = objectToCamel(response as object);
    return towns;
  }
  
  async getUserApps(userId: number) {
    const source$ = this.usersHttpService.getUsersApps(userId);
    const response = await lastValueFrom(source$);
    const apps = response as Array<IApps>;
    return apps;
  }

  async changePassword(userId: number, password: string) {
    const source$ = this.usersHttpService.changePassword(userId, password);
    const response = await lastValueFrom(source$);
    return response;
  }

  async changeStatus(user: IUser, status: string, penaltyEndDate?: Date, penaltyReason?: string) {
    const source$ = this.usersHttpService.changeStatus(user, status, penaltyEndDate, penaltyReason);
    const response = await lastValueFrom(source$);
    const updatedUser = objectToCamel(response as object);
    return updatedUser;
  }

  async addToApp(userId: number, appId: number) {
    const source$ = this.usersHttpService.addToApp(userId, appId);
    const response = await lastValueFrom(source$);
    const apps = response as Array<any>;
    return apps;
  }

  async getBookingsByUser(userId: number) {
    const source$ = this.usersHttpService.getBookingsByUser(userId!);
    const response = await lastValueFrom(source$);
    const bookings = response as IBookingDetails[];
    return bookings;
  }

  async createDocuments(userId: number, documents: IDocumentCreate[]) {
    const source$ = this.usersHttpService.createDocuments(userId, documents);
    const response = await lastValueFrom(source$);
    return response as object;
  }
  
  async deleteDocument(userId: number, documentId: number) {
    const source$ = this.usersHttpService.deleteDocument(documentId, userId);
    const response = await lastValueFrom(source$);
    return response as object;
    }

  getData(response: any) {
    return response.data;
  }

  getStatus(response: any) {
    return response.status;
  }
}
