import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { IAvailability, IAvailabilityFailure, IAvailabilitySuccess, IBookingCreate, IReservationAvailability, IReservationAvailabilityFailure, IServiceInfo } from 'src/app/models';
import { LiteralService, UsersService } from 'src/app/services';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { images } from 'src/images';
import {
  BookingsService,
} from 'src/app/services';

@Component({
  selector: 'app-booking-availabilities',
  templateUrl: './booking-availabilities.component.html',
  styleUrl: './booking-availabilities.component.scss'
})
export class BookingAvailabilitiesComponent implements OnInit, OnChanges {

  @Input() availabilities: any;
  @Input() showedDate: string;
  @Input() userId: any;
  @Input() newBooking: IBookingCreate;
  @Input() serviceInfos: IServiceInfo[];
  @Input() loadingAvailabilities: boolean = true;
  @Input() availabilitiesSelected: any;
  @Input() allAvailabilitiesSelected: boolean;
  @Output() manual: EventEmitter<any> = new EventEmitter();
  @Output() book: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkAllAvailabilitiesSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextAvailability: EventEmitter<any> = new EventEmitter<any>();
  @Output() bookMultiple: EventEmitter<any> = new EventEmitter<any>();
  @Output() showMaxReservationTimeModalEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() showExceedingKmLimitModalEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  public images = images;
  public moment = moment;
  public TooltipPosition = TooltipPosition;


  public interval: any;
  public timeCounter = 0;

  public availabilitiesArray: { date: string; type: string, availability: IAvailabilitySuccess & IAvailabilityFailure }[] = []

  constructor(
    public literalService: LiteralService,
    public bookingsService: BookingsService,
    public userService: UsersService) {

  }

  ngOnInit(): void {
    this.startTimer();
    for (const date in this.availabilities) {
      if (this.availabilities.hasOwnProperty(date)) {
        this.availabilitiesArray.push(...this.availabilities[date].success.map((availability: any) => ({ date, type: 'success', availability })));
        this.availabilitiesArray.push(...this.availabilities[date].failure.map((availability: any) => ({ date, type: 'failure', availability })));
      }
    }
    this.availabilitiesArray.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()); 
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.timeCounter++;
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.availabilities = changes['availabilities'].currentValue;
  }

  selectAvailability(availability: IReservationAvailability | IReservationAvailabilityFailure) {
    this.nextAvailability.emit(availability);
  }

  checkAvailabilitySelected(availabilityId: number) {
    return this.availabilitiesSelected.find((availability: any) => availability.availabilityId === availabilityId);
  }

  isIReservationAvailability = (obj: any): obj is IReservationAvailability => 'optimal' in obj;

  async checkExceptions(eventEmitter: EventEmitter<any>, multiple: boolean = false) {
    let exceedsKmLimit = false;
    let maxReservationTime = false;
    
    const availability = multiple ? this.getFirstSuccess(this.availabilities) : this.availabilitiesSelected[0];
    maxReservationTime = availability.maxBookingTime > 0 && 
        this.timeCounter >= availability.maxBookingTime;

    const nBookings = Object.keys(this.availabilities).filter((availability) => this.availabilities[availability].success.length > 0).length;
    const availabilitySuccess = this.getFirstSuccess(this.availabilities)
    exceedsKmLimit = await this.bookingsService.isExceedingKmLimit(
      this.newBooking.targetUserId,
      availabilitySuccess.inStop.id,
      availabilitySuccess.outStop.id,
      availabilitySuccess.serviceId,
      nBookings
    );

    if (maxReservationTime) {
      this.showMaxReservationTimeModalEventEmitter.emit();
    } else if (exceedsKmLimit) {
      this.showExceedingKmLimitModalEventEmitter.emit();
    } else {
      eventEmitter.emit();
    }
  }

  getFirstSuccess(data: any): any | null {
    for (const dateKey in data) {
      const dateGroup = data[dateKey];
      if (dateGroup.success && dateGroup.success.length > 0) {
        return dateGroup.success[0];
      }
    }
    return null;
  }
  
  async makeBooking() {
    await this.checkExceptions(this.book);
    this.availabilitiesSelected = [];
    this.availabilities = null;
  }
  
  async multipleBooking() {
    await this.checkExceptions(this.bookMultiple, true);
    this.availabilities = null;
  }

  checkIfAlternativeIsOnInitial(availability: IAvailability) {
    if (this.newBooking.originStopId !== availability.inStop.id) {
      return true;
    }
    return false;
  }

  checkIfAlternativeIsOnFinal(availability: IAvailability) {
    if (this.newBooking.destinationStops[0].exitStop.id !== availability.outStop.id) {
      return true;
    }
    return false;
  }

  getAlterationDestinationName(availability: IAvailability, initial: boolean = true) {
    if (this.checkIfAlternativeIsOnInitial(availability) && initial) {
      const origin = this.newBooking.stops.find((stop: any) => stop.id === this.newBooking.originStopId);
      return origin.label;
    }
    if (this.checkIfAlternativeIsOnFinal(availability) && !initial) {
      const destination = this.newBooking.stops.find((stop: any) => stop.id === this.newBooking.destinationStops[0].exitStop.id);
      return destination.label;
    }
    return null;
  }

  hasAlterations(serviceInfoId: number) {
    const serviceInfo = this.serviceInfos.find((serviceInfo: IServiceInfo) => serviceInfo.id === serviceInfoId);
    return serviceInfo ? serviceInfo.alterationMessages.length > 0 : false;
  }

  getAlterationMessageByService(serviceInfoId: number) {
    let messages = `<strong>${this.literalService.get('bookings.alteration', true)}:</strong></br>`;
    const serviceInfo = this.serviceInfos.find((serviceInfo: IServiceInfo) => serviceInfo.id === serviceInfoId);
    serviceInfo && serviceInfo.alterationMessages.forEach((message: string) => {
      messages += message;
    });
    return messages;
  }

  getVariabilityText(availabilityInOutStop: any) {
    if (availabilityInOutStop.minDateTime === availabilityInOutStop.maxDateTime) {
      return this.literalService.get('bookings.tooltips.withoutVariability', true);
    }
    return this.literalService.get('bookings.tooltips.variability', true) + ' <strong>' + moment(availabilityInOutStop.minDateTime).format('HH:mm') + ' - ' + moment(availabilityInOutStop.maxDateTime).format('HH:mm') + '</strong>';
  }

  isFirstInSpecificDate(index: number, date: string): boolean {
    return index === this.availabilitiesArray.findIndex(item => item.date === date);
  }

  hasSuccessAvailabilities() {
    const dates = Object.keys(this.availabilities);
    const result = dates.some((date: any) => this.availabilities[date].success.length > 0)
    return result;
  }

}
