import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-add-to-app',
    templateUrl: './modal-add-to-app.component.html',
    styleUrls: ['./modal-add-to-app.component.scss'],
    standalone: false
})
export class ModalAddToAppComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() text: string;
  @Input() values?: any[];
  @Output() action = new EventEmitter<any>();

  public images = images;

  public selectedValues: number[] = [];

  constructor(public literalService: LiteralService) {}

  isOptionChecked(value: number) {
    return this.selectedValues.includes(value);
  }

  onClickCheckbox(value: number) {
    if (this.selectedValues.includes(value)) {
      const valueIndex = this.selectedValues.findIndex((val) => val === value);
      this.selectedValues.splice(valueIndex, 1);
    } else {
      this.selectedValues.push(value);
    }
  }

  closeModal() {
    this.selectedValues = [];
    this.modal.hide();
  }
}
