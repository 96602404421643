import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Modal } from 'flowbite';
import { AnimationOptions } from 'ngx-lottie';
import { IServiceAvailability, IStop } from 'src/app/models';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { BookingsService, TownsService, UtilsService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
    selector: 'app-modal-change-booking-origin-destination',
    templateUrl: './modal-change-booking-origin-destination.component.html',
    styleUrl: './modal-change-booking-origin-destination.component.scss',
    standalone: false
})
export class ModalChangeBookingOriginDestinationComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() originElement: any;
  @Input() destinationElement: any;
  @Input() booking: IBookingDetails;
  @Input() updating: boolean;
  @Output() action = new EventEmitter<any>();
  @Output() onExceededLimit = new EventEmitter<any>();
  public originSelected: any;
  public destinationSelected: any;
  public availabilitySelected: IServiceAvailability;
  public images = images;

  public optionsBus: AnimationOptions = {
    path: '/assets/animations/bus.json'
  };
  
  constructor(public literalService: LiteralService, public townsService: TownsService, private bookingsService: BookingsService, public utilsService: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.originElement && this.destinationElement) {
      this.originSelected = this.originElement.edit.values.find((stop: IStop) => stop.id === this.originElement.value);
      this.destinationSelected = this.destinationElement.edit.values.find((stop: IStop) => stop.id === this.destinationElement.value);
    }
  }

  async setOrigin(event: any) {
    const stops: any[] = [];
    await this.townsService.getStopsFrom(this.booking.service.town.id!, event, this.booking.availability.dateTime, false).then((res: any[]) => {
      res.map((stop: any) =>{
        stops.push(
          {
            id: stop.id,
            value: stop.id,
            label: stop.name
          }
        );
      });
    });
    stops.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      } else if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    this.destinationElement.edit.values = stops;
    this.originSelected = this.originElement.edit.values.find((stop: IStop) => stop.id === event);
  };

  setDestination(event: any) {
    this.destinationSelected = this.destinationElement.edit.values.find((stop: IStop) => stop.id === event);
  };

  async onSubmit() {
    const kmExceeded = await this.bookingsService.isExceedingKmLimit(
      this.booking.userId,
      this.originSelected.id,
      this.destinationSelected.id,
      this.booking.routeId,
      1
    );
    this.booking.destination = {
      id: this.destinationSelected.id, 
      serviceStopId: this.destinationSelected.id, 
      name: this.destinationSelected.label
    };
    this.booking.origin = {
      id: this.originSelected.id, 
      serviceStopId: this.originSelected.id, 
      name: this.originSelected.label
    };
    if (kmExceeded) this.onExceededLimit.emit(this.booking);
    else {
      this.modal.toggle();
      this.action.emit(this.booking);
    }
  };
}
