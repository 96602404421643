<div class="flex w-[411px]">
    <section class="w-full">
        <article id="header" class="flex primary-green-2-background rounded-ss-lg rounded-se-lg">
            <div class="flex ml-5 my-4 items-center w-full">
                <h1 class="font-bold text-[22px] primary-purple-text">{{ literalService.get('services.title', true) }}</h1>
            </div>
            <div class="flex items-center mr-8">
                <img [src]="images.services.addService" alt="addService" (click)="addService.emit()" class="w-6 h-6 mr-2 cursor-pointer" />
                <!-- <img id="gtfsIcon" [src]="images.services.import" alt="gtfs" (click)="showGTFs($event)" class="w-6 h-6 mr-2 cursor-pointer" /> -->
                <img id="gtfsImportIcon" [src]="images.services.export" alt="gtfsImport" (click)="showGTFs($event)" class="w-6 h-6 mr-2 cursor-pointer" />
                <img id="gtfsExportIcon" [src]="images.services.import" alt="gtfsExport" (click)="openExportGTFSModal.emit()" class="w-6 h-6 cursor-pointer" />
            </div>
            <!-- <article [ngClass]="{'hidden': showGTFsOptions}">

            </article> -->
            <div id="gtfsOptions" class="absolute w-52 primary-purple-light-2-background z-10 rounded-lg" [ngClass]="{'fadeIn': showGTFsOptions, 'fadeOut': !showGTFsOptions}">
                <div class="p-4">
                    <button (click)="openGTFSModal.emit()" class="flex w-full rounded bg-white pr-4 pl-2 py-2 mb-2 cursor-pointer items-center">
                        <label class="cursor-pointer">{{ literalService.get('services.actions.importGTFS', true) }}</label>
                    </button>
                    <button (click)="openImportCSVModal.emit()" class="flex w-full rounded bg-white pr-4 pl-2 py-2 mb-2 cursor-pointer items-center">
                        <label class="cursor-pointer">{{ literalService.get('services.actions.importCSV', true) }}</label>
                    </button>
                </div>
            </div>
        </article>
        <article id="search" class="flex bg-white">
            <div class="mx-5 my-4 items-center w-full">
                <app-service-search-bar
                    [placeholder]="literalService.get('services.search', true)"
                    [searching]="searching"
                    (filter)="filter($event)"
                    (searchEventEmitter)="search($event)">
                </app-service-search-bar>
            </div>
        </article>
        <article id="body" class="flex bg-white">
            <div class="mx-5 my-5 items-center min-w-[371px] max-w-[371px]">
                @for (serviceInfo of serviceInfos().value; track $index) {
                    <div class="row">
                        <app-service-row [service]="serviceInfo" [serviceInfoSelected]="serviceInfoSelected()" (showServiceInfoData)="selectServiceInfo.emit(serviceInfo)" (showRoutes)="showRoutes.emit(serviceInfo)"></app-service-row>
                    </div>
                }
            </div>
        </article>
        <article id="footer" class="flex items-center bg-white rounded-es-lg rounded-ee-lg pb-[70px]">
            <!-- <label>{{ serviceInfos().pagination.numberOfElements }}</label> -->
            <app-pagination id="serviceInfoPagination" [id]="'serviceInfoPagination'" class="mx-5" [pagination]="serviceInfos().pagination" (changePage)="changePageService($event)" [small]="true" [maxLength]="5" [right]="false" />
        </article>
    </section>
</div>
  