import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Modal } from 'flowbite';
import { ClipboardService } from 'ngx-clipboard';
import { AppComponent } from 'src/app/app.component';
import { getBookingForm, getStops, getCancelledBookingForm } from 'src/app/forms/bookingForms';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { IBookingUpdate } from 'src/app/models/booking/bookingUpdate';
import { NotificationTypes } from 'src/app/models/enums';
import { BookingsService, MapUtilsService, NotificationService, RoleService, ServicesService, TownsService, UtilsService } from 'src/app/services';
import { FavouritesService } from 'src/app/services/favourites/favourites.service';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { environment } from 'src/environments/environment';
import { images } from 'src/images';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrl: './booking-details.component.scss',
})
export class BookingDetailsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() booking: IBookingDetails = {} as IBookingDetails;
  @Output() openInFullMap = new EventEmitter<IBookingDetails>();
  @Output() editBooking = new EventEmitter<IBookingDetails>();
  @Output() updateBookings = new EventEmitter();
  @Output() deleteBooking = new EventEmitter();
  @Output() markAsAFault = new EventEmitter();
  @Output() closeDetails = new EventEmitter();

  public map: google.maps.Map;

  public cancellationReason = this.literalService.get('bookings.data.reason', true);

  public expanded = false;
  public changeKmsShowed = false;
  public changeSeatsShowed = false;
  public changeAvailabilityShowed = false;
  public changeOriginDestinationShowed = false;
  public updatingOriginDestination = false;
  public createFavouriteShowed = false;
  public setFaultShowed = false;
  public edit = false;
  public newBooking = false;
  public isFullMap = false;
  public viewMap = false;

  public bookingForm: any;

  public bookingCopy: IBookingDetails;
  public isHoveringOriginDestinationDetail: boolean;
  public changeKms: Modal;
  public changeSeats: Modal;
  public changeAvailability: Modal;
  public changeOriginDestination: Modal;
  public createFavouriteModal: Modal;
  public setFaultModal: Modal;

  public creatingFavourite = false;

  public availablityElement: any;
  public originElement: any;
  public destinationElement: any;

  public images = images;
  public TooltipPosition = TooltipPosition;

  public faultModalShow = false;

  constructor(
    public literalService: LiteralService,
    private mapUtilsService: MapUtilsService,
    public router: Router,
    public appComponent: AppComponent,
    private clipboardService: ClipboardService,
    private servicesService: ServicesService,
    public roleService: RoleService,
    private notificationService: NotificationService,
    private bookingsService: BookingsService,
    public utilsService: UtilsService,
    private favouritesService: FavouritesService,
    private townsService: TownsService
  ) { }

  async ngOnInit() {
    await this.initMap();
  }

  ngAfterViewInit(): void {
    this.loadFormAndMap();
    this.initializeModals();
  }

  async ngOnChanges() {
    await this.initMap();
    this.map?.data.forEach((feature: any) => this.map.data.remove(feature));
    this.loadFormAndMap();
  }

  initMap = async () => {
    const { Map } = (await google.maps.importLibrary(
      'maps',
    )) as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      zoom: 15,
      disableDefaultUI: true,
      mapId: environment.mapId,
    });
  };

  loadFormAndMap = async () => {
    if (this.map && this.booking) {
      this.booking && this.booking.cancelled &&
        await getCancelledBookingForm(this.booking, this.literalService, this.servicesService).then((res: any) => {
          this.bookingForm = res;
        });
      !this.booking.cancelled &&
      await getBookingForm(this.booking, this.literalService, this.servicesService, this.townsService).then((res: any) => {
          this.bookingForm = res;
        });
      this.map.data.setStyle({
        strokeColor: this.booking.service && this.booking.service.color !== '#FFFFFFFF' ? this.booking.service.color : '#000000',
        strokeWeight: 5,
      });
      if (this.booking.path) {
        this.map.data.addGeoJson(this.booking.path);
        const bounds = new google.maps.LatLngBounds();
        const markers: any[] = [];
        const markerOrigin = document.getElementById('origin') as HTMLElement;
        const markerDestination = document.getElementById(
          'destination',
        ) as HTMLElement;
        markers.push({
          element: markerOrigin,
          latitude: this.booking.path.features[0].geometry.coordinates[0][1],
          longitude: this.booking.path.features[0].geometry.coordinates[0][0],
        });
        markers.push({
          element: markerDestination,
          latitude: this.booking.path.features
            .at(-1)
            .geometry.coordinates.at(-1)[1],
          longitude: this.booking.path.features
            .at(-1)
            .geometry.coordinates.at(-1)[0],
        });
        this.mapUtilsService.addCustomMarkers(this.map, markers);
        this.booking.path.features.map((feature: any) => {
          bounds.union(
            new google.maps.LatLngBounds(
              new google.maps.LatLng(
                feature.geometry.coordinates[0][1],
                feature.geometry.coordinates[0][0],
              ),
            ),
          );
        });
        setTimeout(() => {
          this.map.fitBounds(bounds);
        }, 500);
      }
    }
  };

  initializeModals = () => {
    this.changeKms = new Modal(document.getElementById('changeKms'), {
      closable: true,
      onHide: () => (this.changeKmsShowed = false),
      onShow: () => (this.changeKmsShowed = true),
    });
    this.changeSeats = new Modal(document.getElementById('changeSeats'), {
      closable: true,
      onHide: () => (this.changeSeatsShowed = false),
      onShow: () => (this.changeSeatsShowed = true),
    });
    this.changeAvailability = new Modal(document.getElementById('changeAvailability'), {
      closable: true,
      onHide: () => (this.changeAvailabilityShowed = false),
      onShow: () => (this.changeAvailabilityShowed = true),
    });
    this.changeOriginDestination = new Modal(document.getElementById('changeOriginDestination'), {
      closable: true,
      onHide: () => (this.changeOriginDestinationShowed = false),
      onShow: () => (this.changeOriginDestinationShowed = true),
    });
    this.createFavouriteModal = new Modal(document.getElementById('createFavouriteFromDetail'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.createFavouriteShowed = false),
      onShow: () => (this.createFavouriteShowed = true),
    });
    this.setFaultModal = new Modal(document.getElementById('setFault'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.setFaultShowed = false),
      onShow: () => (this.setFaultShowed = true),
    });
  };

  closeModals = () => {
    if (this.changeKmsShowed) this.changeKms.toggle();
    if (this.changeSeatsShowed) this.changeSeats.toggle();
    if (this.changeAvailabilityShowed) this.changeAvailability.toggle();
    if (this.changeOriginDestinationShowed) this.changeOriginDestination.toggle();
    if (this.createFavouriteShowed) this.createFavouriteModal.toggle();
    if (this.setFaultShowed) this.setFaultModal.toggle();
  };

  openModal = async (modal: any) => {
    this.bookingCopy = { ...this.booking };
    this.availablityElement = this.bookingForm.filter((x: { title: string; }) => x.title === 'bookings.data.availability')[0];
    this.originElement = this.bookingForm.filter((x: { title: string; }) => x.title === 'bookings.data.originStop')[0];
    if (this.originElement?.edit) {
      this.originElement.edit = {
        ...this.originElement.edit,  // spread existing edit properties
        values: await getStops(this.townsService,this.booking)  // only update the values
      };
    }
    this.destinationElement = this.bookingForm.filter((x: { title: string; }) => x.title === 'bookings.data.destinationStop')[0];
    if (this.destinationElement?.edit) {
      this.destinationElement.edit = {
        ...this.destinationElement.edit,  // spread existing edit properties
        values: await getStops(this.townsService,this.booking)  // only update the values
      };
    }
    
    if (modal === 'kms') {
      this.booking.kms = Number(this.booking.kms.toFixed(2));
      this.changeKms.toggle();
    }
    if (modal === 'seats') this.changeSeats.toggle();
    if (modal === 'availability') this.changeAvailability.toggle();
    if (modal === 'origin' || modal === 'destination') this.changeOriginDestination.toggle();
  };

  submitBookingEdit = async (submitBooking: IBookingDetails) => {
    this.updatingOriginDestination = true;
    const bookingUpdate: IBookingUpdate = {} as IBookingUpdate;
    bookingUpdate.loggedUserId = submitBooking.userId;
    bookingUpdate.availabilityId = submitBooking.availability.id;
    bookingUpdate.tripId = submitBooking.tripId;
    bookingUpdate.bookingId = submitBooking.id;
    bookingUpdate.targetUserId = submitBooking.userId;
    bookingUpdate.availabilityId = submitBooking.availability.id;
    bookingUpdate.originStop = submitBooking.origin;
    bookingUpdate.destinationStop = submitBooking.destination;
    bookingUpdate.seats = submitBooking.seats;
    bookingUpdate.prmSeats = submitBooking.prmSeats;
    this.booking.origin = submitBooking.origin;
    this.booking.destination = submitBooking.destination;
    this.booking.availability = submitBooking.availability;
    this.booking.seats = submitBooking.seats;
    this.booking.prmSeats = submitBooking.prmSeats;
    try {
      this.booking = await this.bookingsService.updateBooking(bookingUpdate);
      this.updateBookings.emit();
      this.bookingForm = await getBookingForm(this.booking, this.literalService, this.servicesService, this.townsService);
      this.notificationService.image = images.sidebar.bookings;
      this.notificationService.title = 'bookings.actions.editBooking.title';
      this.notificationService.message = 'bookings.actions.editBooking.text';
      this.notificationService.translate = true;
      this.notificationService.show(NotificationTypes.SUCCESS);
    } catch (error) {
      this.notificationService.image = images.notification.error;
      this.notificationService.title = 'errorOccurred';
      this.notificationService.message = '0';
      this.notificationService.translate = true;
      this.notificationService.show(NotificationTypes.DANGER);
    }
    this.updatingOriginDestination = false;
    if (this.changeOriginDestinationShowed) this.changeOriginDestination.toggle();
  };

  submitBookingKmsEdit = async (kms: number) => {
    try {
      this.booking = await this.bookingsService.updateKms(this.booking.id!, kms);
      this.bookingForm = await getBookingForm(this.booking, this.literalService, this.servicesService, this.townsService);
      this.notificationService.image = images.sidebar.bookings;
      this.notificationService.title = 'bookings.actions.editBooking.title';
      this.notificationService.message = 'bookings.actions.editBooking.text';
      this.notificationService.translate = true;
      this.notificationService.show(NotificationTypes.SUCCESS);
    } catch (error) {
      this.notificationService.image = images.notification.error;
      this.notificationService.title = 'errorOccurred';
      this.notificationService.message = '0';
      this.notificationService.translate = true;
      this.notificationService.show(NotificationTypes.DANGER);
    }
  };

  setIsHoveringOriginDestinationDetail = (isHovering: boolean) => {
    this.isHoveringOriginDestinationDetail = isHovering;
  };

  close = () => {
    this.expanded = false;
    this.edit = false;
    this.newBooking = false;
    this.closeDetails.emit();
  };

  goToUser(userId: number): void {
    this.appComponent.setRouteName('users');
    this.router.navigate(['/users/' + userId]);
  }

  goToTrip(tripId: number): void {
    this.appComponent.setRouteName('trips');
    this.router.navigate(['/trips/' + tripId]);
  }

  copyLink(): void {
    const domain = this.servicesService.getCurrentDomain();
    let path = domain + '/bookings/' + this.booking.id + `?cancelled=${this.booking.cancelled}`;
    this.clipboardService.copyFromContent(path);
  }

  createFavourite = async (favouriteName: string) => {
    this.creatingFavourite = true;
    await this.favouritesService.createFavourite(favouriteName, this.booking).then(() => {
      this.notificationService.image = images.sidebar.bookings;
      this.notificationService.title = this.literalService.get(
        `bookings.actions.createFavourite.successTitle`,
        true,
      );
      this.notificationService.message = this.literalService.get(
        `bookings.actions.createFavourite.successText`,
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
    }, (error: any) => {
      console.log(error);
      this.creatingFavourite = false;
    });
    this.createFavouriteModal.toggle();
    this.creatingFavourite = false;
  };
}
