import { IPagination } from '../states';

export const initialPaginationState: IPagination = {
  numberOfElements: 10,
  totalPages: 1,
  totalElements: 1,
  actualPage: 1,
  first: true,
  last: false,
  size: 10
};
