import { Injectable } from '@angular/core';
import { TownsHttpService } from './towns.http.service';
import { objectToCamel } from 'ts-case-convert';
import { lastValueFrom } from 'rxjs';
import { IFindResult } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class TownsService {
  constructor(private townsHttpService: TownsHttpService) {}

  async getTown(townId: number) {
    const source$ = this.townsHttpService.getTown(townId);
    const response = await lastValueFrom(source$);
    const town = objectToCamel(response as object);
    return town;
  }

  async getTowns(page?: number, size?: number) {
    const source$ = this.townsHttpService.getTowns(page, size);
    const response = await lastValueFrom(source$);
    const towns = objectToCamel(response as object);
    const townsArray = this.getData(towns);
    return townsArray;
  }

  async getServices(townId: number) {
    const source$ = this.townsHttpService.getServices(townId);
    const response = await lastValueFrom(source$);
    const serviceInfos = objectToCamel(response as object);
    const serviceInfoArray = this.getData(serviceInfos);
    return serviceInfoArray;
  }

  async getServicesList(townId: number, page?: number, size?: number) {
    const source$ = this.townsHttpService.getServicesList(townId, page, size);
    const response = await lastValueFrom(source$);
    const serviceInfos = objectToCamel(response as object);
    const serviceInfoArray = this.getData(serviceInfos);
    return serviceInfoArray;
  }

  async getStopsFrom(townId: number, stopId?: number, dateTime?: Date, mergedStops?: boolean, routeId?: number) {
    const source$ = this.townsHttpService.getStopsFrom(townId, stopId, dateTime, mergedStops, routeId);
    const response = await lastValueFrom(source$);
    const stops = objectToCamel(response as object[]);
    return stops;
  }

  async getAvailabilities(id: number, userId: number, inStopId: number, outStopId: number, seats: number, prmSeats: number, dateTimes: any, isArrivalTime: boolean = false, reservationId?: number, isSingleReservation?: boolean) {
    const source$ = this.townsHttpService.getAvailabilities(id, userId, inStopId, outStopId, seats, prmSeats, dateTimes, isArrivalTime, reservationId, isSingleReservation);
    const response = await lastValueFrom(source$);
    const availabilities = objectToCamel(response as IFindResult);
    return availabilities;
  }

  async getAvailabilitiesPositions(id: number, userId: number, inLatitude: number, inLongitude: number, inName: string, outLatitude: number, outLongitude: number, outName: string, seats: number, prmSeats: number, dateTimes: any, isArrivalTime: boolean = false, reservationId?: number, isSingleReservation?: boolean) {
    const source$ = this.townsHttpService.getAvailabilitiesByPositions(id, userId, inLatitude, inLongitude, inName, outLatitude, outLongitude, outName, seats, prmSeats, dateTimes, isArrivalTime, reservationId);
    const response = await lastValueFrom(source$);
    const availabilities = objectToCamel(response as IFindResult);
    return availabilities;
  }

  getData(response: any) {
    return response.data;
  }
}
