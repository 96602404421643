import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Modal } from 'flowbite';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { IBase, IBooking, IBookingCreate, IFavourites } from 'src/app/models';
import { IBookingDetails } from 'src/app/models/booking/bookingDetails';
import { BookingOrigin, ExpeditionStatus, ExpeditionType, NotificationTypes, UserType } from 'src/app/models/enums';
import { BookingsResolver } from 'src/app/resolvers';
import {
  BookingsService,
  MapUtilsService,
  NotificationService,
  TownsService,
  UsersService,
  UtilsService
} from 'src/app/services';
import { FavouritesService } from 'src/app/services/favourites/favourites.service';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { initialBookingsState } from 'src/app/store/initialisation/bookings';
import { environment } from 'src/environments/environment';
import { images } from 'src/images';
import { IServiceInfo } from '../../models';
import { isDoor2DoorService } from 'src/app/forms/bookingForms';
import { IFavouritesState } from 'src/app/store/states';
import { initialFavouritesState } from 'src/app/store/initialisation/favourites';

@Component({
    selector: 'app-bookings',
    templateUrl: './bookings.component.html',
    styleUrls: ['./bookings.component.scss'],
    standalone: false
})
export class BookingsComponent implements OnInit, AfterViewInit, OnDestroy {
  public images = images;
  public bookings = initialBookingsState;

  public clientsSelected: IBase[] = [];
  public townsSelected: IBase[] = [];
  public typesSelected: ExpeditionType[] = [];
  public statusSelected: ExpeditionStatus[] = [];
  public cancelledStatusSelected: boolean;
  public maxKmsPerMonth: number;
  public distanceTravelled: number;
  public searchValue: string;
  public book: any;

  public TooltipPosition = TooltipPosition;
  public BookingOrigin = BookingOrigin;
  public ExpeditionStatus = ExpeditionStatus;

  public bookingSelected: any;
  public bookingSelectedCopy: any;
  public rebookBooking: boolean;
  public newBooking = false;
  public creatingBooking = false;
  public edit = false;
  public isFullMap = false;

  public bookingSelectedForFavourite?: IBooking;
  public bookingSelectedForNotified: IBookingDetails;

  public userType = UserType;

  public map: google.maps.Map;
  private markers: any[] = [];
  public serviceInfos: IServiceInfo[] = [];

  public newBookingObject: IBookingCreate = {} as IBookingCreate;

  public maxReservationTimeModal: Modal;
  public exceedingKmLimitModal: Modal;
  public blockedUserModal: Modal;
  public exceedingKmLimitShowed = false;
  public maxReservationTimeShowed = false;
  public blockedUserShowed = false;
  public deleteBookingModal: Modal;
  public deleteBookingShowed = false;
  public viewFavourites: Modal;
  public viewFavouritesShowed: boolean = false;
  public viewFavouritesSelected: string;
  public favourites: IFavouritesState = initialFavouritesState;
  public favouriteUserId: number;
  public deletingBooking = false;
  public createFavouriteModal: Modal;
  public createFavouriteShowed = false;
  public alterationModal: Modal;
  public alterationShowed = false;
  public notifiedBookingModal: Modal;
  public notifiedBookingShowed = false;
  public ticketingStatusModal: Modal;
  public ticketingStatusShowed = false;

  public reason: string;
  public penaltyEndDate: Date;
  public penaltyReason: string;
  private routerSubscription: Subscription;

  public creatingFavourite = false;
  public bookingFromFavourite = false;
  public bookingFromFavouriteUserId: number;

  public bookingFromUsers = false;

  public interval: any;
  public searching: boolean = false;

  public markAsFaultModal: Modal;
  public markAsFaultModalShowed: boolean = false;
  public hasAlterations = false;

  public closeExceedingKmHandler = false;
  public availabilitiesToBook: any[] = [];

  constructor(
    renderer: Renderer2,
    public literalService: LiteralService,
    private bookingsResolver: BookingsResolver,
    private bookingsService: BookingsService,
    private favouritesService: FavouritesService,
    private activatedRoute: ActivatedRoute,
    public utilsService: UtilsService,
    public mapUtilsService: MapUtilsService,
    private notificationService: NotificationService,
    private titleService: Title,
    private userService: UsersService,
    public router: Router,
    public appComponent: AppComponent,
    public townsService: TownsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    renderer.listen('window', 'click', (e: Event) => {
      if (!this.utilsService.isTextSelected() && document.body.contains(e.target as Node)) {
        const modal = document.getElementById('bookingDetailsContainer')!;
        const componentOpen = document.getElementById('bookingsContainer')!;
        const modals: Node[] = [];
        modals.push(document.getElementById('maxReservationTime') as Node);
        modals.push(document.getElementById('exceedingKmLimit') as Node);
        modals.push(document.getElementById('deleteBooking') as Node);
        modals.push(document.getElementById('createFavourite') as Node);
        modals.push(document.getElementById('viewFavourites') as Node);
        modals.push(document.getElementById('changeKms') as Node);
        modals.push(document.getElementById('changeAvailability') as Node);
        modals.push(document.getElementById('changeOriginDestination') as Node);
        modals.push(document.getElementById('changeSeats') as Node);
        modals.push(document.getElementById('createFavouriteFromDetail') as Node);
        modals.push(document.getElementById('setFault') as Node);
        modals.push(document.getElementById('ticketingStatus') as Node);
        const close = utilsService.closeCustomModalsWithModals(e, modal, modals, componentOpen);
        if (close) {
          this.bookingSelected = undefined;
        }
      }
    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    this.titleService.setTitle(this.literalService.get('sidebar.bookings', true) + ' - NEMI Backoffice');

    let isCancelled = false;
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.get('userId')) {
        if (params.get('favouriteId') || history.state.fromFavourite) {
            this.bookingFromFavourite = true;
            this.bookingFromFavouriteUserId = Number(params.get('userId'));
        }
        else this.bookingFromUsers = true;
        
      }
      isCancelled = params.get('cancelled') === 'true';
    });
    // Retrieve the object ID from the route parameters
    this.activatedRoute.params && this.activatedRoute.params.subscribe((params) => {
      const bookingId: number = params['id'];
      bookingId && this.showBookingData(bookingId, isCancelled);
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    this.initializeModals();

    this.interval = setInterval(() => {
      this.getBookings();
    }, 60000);

    // Retrieve the object ID from the route parameters
    this.activatedRoute.data && this.activatedRoute.data.subscribe((data) => {
      data['new'] && this.addBooking();
    });

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Close the modal when navigating away from the current view
        this.closeModals();
      }
    });
  }

  initializeModals() {
    this.maxReservationTimeModal = new Modal(document.getElementById('maxReservationTime'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.maxReservationTimeShowed = false),
      onShow: () => (this.maxReservationTimeShowed = true),
    });
    this.exceedingKmLimitModal = new Modal(document.getElementById('exceedingKmLimit'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.exceedingKmLimitShowed = false),
      onShow: () => (this.exceedingKmLimitShowed = true),
    });
    this.deleteBookingModal = new Modal(document.getElementById('deleteBooking'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        (this.deleteBookingShowed = false);
        this.reason = '';
      },
      onShow: () => (this.deleteBookingShowed = true),
    });
    this.blockedUserModal = new Modal(document.getElementById('blockedUserBooking'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        (this.blockedUserShowed = false);
        this.reason = '';
      },
      onShow: () => (this.blockedUserShowed = true),
    });
    this.markAsFaultModal = new Modal(document.getElementById('markAsFault'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.markAsFaultModalShowed = false),
      onShow: () => (this.markAsFaultModalShowed = true),
    });
    this.createFavouriteModal = new Modal(document.getElementById('createFavourite'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.createFavouriteShowed = false),
      onShow: () => (this.createFavouriteShowed = true),
    });
    this.viewFavourites = new Modal(document.getElementById('viewFavourites'), {
      placement: 'center',
      closable: true,
      onHide: () => (this.viewFavouritesShowed = false),
      onShow: () => (this.viewFavouritesShowed = true),
    });
    this.alterationModal = new Modal(document.getElementById('alteration'), {
      closable: true,
      onHide: () => (this.alterationShowed = false),
      onShow: () => (this.alterationShowed = true),
    });
    this.notifiedBookingModal = new Modal(document.getElementById('notifiedBooking'), {
      closable: true,
      onHide: () => (this.notifiedBookingShowed = false),
      onShow: () => (this.notifiedBookingShowed = true),
    });
    this.ticketingStatusModal = new Modal(document.getElementById('ticketingStatus'), {
      closable: true,
      onHide: () => {
        this.ticketingStatusShowed = false;
        this.bookingSelected = this.bookingSelectedCopy;
      },
      onShow: () => {
        this.ticketingStatusShowed = true;
        this.bookingSelectedCopy = { ...this.bookingSelected };
      },
    });
  };

  async initMap() {
    const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
    
    const center = await this.userService.getLoggedUserTown();
    
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { 
        lat: center ? center.latitude : 41.4696546,
        lng: center ? center.longitude : 2.0596017 
      },
      zoom: 15,
      disableDefaultUI: true,
      mapId: environment.mapId
    });
  }

  closeModals() {
    if (this.maxReservationTimeShowed) this.maxReservationTimeModal.toggle();
    if (this.exceedingKmLimitShowed) this.exceedingKmLimitModal.toggle();
    if (this.blockedUserShowed) this.blockedUserModal.toggle();
    if (this.deleteBookingShowed) this.deleteBookingModal.toggle();
    if (this.markAsFaultModalShowed) this.markAsFaultModal.toggle();
    if (this.createFavouriteShowed) this.createFavouriteModal.toggle();
    if (this.viewFavouritesShowed) this.viewFavourites.toggle();
    if (this.notifiedBookingShowed) this.notifiedBookingModal.toggle();
    if (this.ticketingStatusShowed) this.ticketingStatusModal.toggle();
  };

  async getBookings() {
    this.bookings.pagination.numberOfElements = 10;
    this.activatedRoute.snapshot.data['bookings'] = this.bookings;
    await this.bookingsResolver.getBookings(
      this.activatedRoute.snapshot,
      this.clientsSelected,
      this.townsSelected,
      this.typesSelected,
      this.statusSelected,
      this.cancelledStatusSelected,
      this.searchValue
    );
  }

  async changePage(event: { isNext: boolean, specificPage?: number }) {
    this.searching = true;
    this.bookings = initialBookingsState;
    this.bookings.value = [];
    if (!event.specificPage) {
      this.bookings.pagination.actualPage = event.isNext
        ? this.bookings.pagination.actualPage + 1
        : this.bookings.pagination.actualPage - 1;
    } else {
      this.bookings.pagination.actualPage = event.specificPage;
    }
    await this.getBookings();
    this.searching = false;
  };

  async showBookingData(bookingId: number, cancelled: boolean) {
    try {
      this.bookingSelected = (await this.bookingsService.getBooking(
        bookingId,
        this.cancelledStatusSelected || cancelled
      )) as IBookingDetails;
      
    } catch {
      // go to bookings again if booking not found
      this.router.navigate(['/bookings']);
    }
  };

  async openInFullMap(booking: IBookingDetails) {
    this.isFullMap = true;
    await this.initMap();

    if (this.map && booking) {
      this.map.data.addGeoJson(booking.path);
      this.map.data.setStyle({
        strokeColor: booking.service.color !== '#FFFFFFFF' ? booking.service.color : '#000000',
        strokeWeight: 5,
      });
      const bounds = new google.maps.LatLngBounds();
      const markers: any[] = [];
      const markerOrigin = document.getElementById('origin') as HTMLElement;
      markerOrigin.classList.remove('hidden');
      const markerDestination = document.getElementById('destination') as HTMLElement;
      markerDestination.classList.remove('hidden');
      markers.push({
        element: markerOrigin,
        latitude: booking.origin.latitude,
        longitude: booking.origin.longitude,
        name: booking.origin.name
      });
      markers.push({
        element: markerDestination,
        latitude: booking.destination.latitude,
        longitude: booking.destination.longitude,
        name: booking.destination.name
      });
      this.mapUtilsService.addCustomMarkers(this.map, markers);
      booking.path.features.map((feature: any) => {
        bounds.union(
          new google.maps.LatLngBounds(
            new google.maps.LatLng(
              feature.geometry.coordinates[0][1],
              feature.geometry.coordinates[0][0],
            ),
          ),
        );
      });
      setTimeout(() => {
        const padding = { top: 50, bottom: 0, left: 300, right: 0 };
        this.map.fitBounds(bounds, padding);
      }, 500);
    }
  };

  goToNewBooking(): void {
    this.router.navigate(['/bookings/new']);
  }

  goToBookings(): void {
    this.router.navigate(['/bookings']);
  }

  goBackToUsers(): void {
    this.router.navigate(['/users/' + this.bookingFromFavouriteUserId], { state: { fromFavouriteBooking: true } });
  }

  close() {
    if (this.isFullMap) {
      this.isFullMap = false;
    } else {
      this.newBookingObject = {} as IBookingCreate;
      if (this.bookingFromFavourite) {
        this.goBackToUsers();
      } else {
        this.goToBookings();
      }
    }
  };

  async addBooking() {
    this.isFullMap = true;
    this.newBooking = true;
    // this.bookingSelected = undefined
    this.initMap();
  };

  async getServiceInfos(event: any) {
    const serviceInfos = event.serviceInfos;
    this.hasAlterations = serviceInfos.some((serviceInfo: IServiceInfo) => serviceInfo.alterationMessages.length > 0);
    // const townId = event.townId;
    this.serviceInfos = serviceInfos;
    const bounds = new google.maps.LatLngBounds();
    const stops: any[] = [];
    const stopsAvailable: any[] = [];

    const townInfo: any = await this.townsService.getTown(event.townId);
    bounds.union(
      new google.maps.LatLngBounds(
        new google.maps.LatLng(
          townInfo.latitude,
          townInfo.longitude,
        ),
      ),
    );

    const isD2DBooking = isDoor2DoorService(serviceInfos);
    let onOutlineClick = (event: any) => {};
    if (isD2DBooking) {
      onOutlineClick = (event: any) => {
        let bookingObjectCopy = structuredClone(this.newBookingObject);
        if (!bookingObjectCopy.originStop || !bookingObjectCopy.destinationStop) {
          const lat = event.latLng.lat();
          const lng = event.latLng.lng();
          this.mapUtilsService.getNearestRoad(lat, lng).then(async (placeDetails: any) => {
            let newBookingType = 'origin';
            let stopDetails = {
              lat: placeDetails?.location.lat,
              lng: placeDetails?.location.lng,
              name: placeDetails.name
            };
            if (!bookingObjectCopy.originStop) {
              bookingObjectCopy.originStop = stopDetails;
            } else if (!bookingObjectCopy.destinationStop) {
              bookingObjectCopy.destinationStop = stopDetails;
              bookingObjectCopy.destinationStops = [{
                exitStop: {
                  id: undefined,
                  name: placeDetails.name
                },
                prm: bookingObjectCopy.user.prm
              }]
              newBookingType = 'destination';
            }
            this.newBookingObject = bookingObjectCopy;
            this.changeDetectorRef.detectChanges();
            this.selectOD({
              newBooking: this.newBookingObject,
              type: newBookingType
            })
  
          });
        }
      };
    }
    this.mapUtilsService.drawOutlines(this.map, serviceInfos, onOutlineClick, isD2DBooking);

    if (!isD2DBooking) {
      serviceInfos.forEach((serviceInfo: IServiceInfo) => {
        serviceInfo.stops.forEach((stop: any) => {
          const restrictedOrigin = this.availableOrigins(serviceInfo, stop);
          if (!restrictedOrigin) {
            stopsAvailable.push(stop);
          } else {
            const exists = stopsAvailable.some((stopAvailable: any) => stopAvailable.id === stop.id);
            if (exists) {
              stopsAvailable.push(stop);
            }
          }
        });
      });
      serviceInfos.forEach((serviceInfo: IServiceInfo) => {
        serviceInfo.stops.forEach((stop: any) => {
          const available = stopsAvailable.find((stopAvailable: any) => stopAvailable.id === stop.id);
          let stopMarker: HTMLElement;
          if (!available) {
            stopMarker = document.getElementById('stopMarkerDisabled') as HTMLElement;
          } else {
            stopMarker = document.getElementById('stopMarker') as HTMLElement;
          }
          const newMarker = stopMarker.cloneNode(true) as HTMLElement;
          newMarker.setAttribute('id', 'stop' + serviceInfo.id + stop.id);
          newMarker.classList.remove('invisible');
          const exists = stops.find((s: any) => s.id === stop.id);
          if (!exists) {
            stops.push({
              id: stop.id,
              name: stop.name,
              marker: newMarker,
              lat: stop.location.geometry.coordinates[1],
              lng: stop.location.geometry.coordinates[0],
            });
          }
        });
      });
      stops.forEach((stop: any) => {
        bounds.union(
          new google.maps.LatLngBounds(
            new google.maps.LatLng(
              stop.lat,
              stop.lng,
            ),
          ),
        );
      });
      this.mapUtilsService.addClustersMarkers(this.map, stops);
    }
    const padding = { top: 20, bottom: 150, left: 300, right: 0 };
    this.map.fitBounds(bounds, padding);
    this.map.setZoom(Math.min(this.map.getZoom()!, 15));
  };

  availableOrigins(serviceInfo: IServiceInfo, stop: any) {
    const restricted = serviceInfo.restrictedOrigins.some((origin: any) => origin === stop.id);
    return restricted;
  };

  selectOD(newBooking: any) {
    if (newBooking.isSingleReservation) {
      this.markers = [];
      this.mapUtilsService.addCustomMarkers(this.map, this.markers, true);
    }
    if (newBooking.type === 'origin') {
      this.updateMarker('origin', newBooking.newBooking.originStop);
    } else if (newBooking.type === 'destination') {
      this.updateMarker('destination', newBooking.newBooking.destinationStop);
    }
    this.mapUtilsService.addCustomMarkers(this.map, this.markers, false);
  }

  updateMarker(type: string, newMarker: any) {
    const markerElement = document.getElementById(type) as HTMLElement;
    if (markerElement) markerElement.classList.add('hidden');
    const marker = markerElement.cloneNode(true) as HTMLElement;
    if (marker) marker.classList.remove('hidden');
    
    const index = this.markers.findIndex((marker: any) => marker.id === type);
    if (index > -1) {
      this.markers.splice(index, 1);
    }
    this.markers = this.markers.filter((marker: any) => marker.id !== type);
    if (newMarker) {
      this.markers.push({
        id: type,
        name: newMarker?.name,
        element: marker,
        latitude: newMarker?.lat,
        longitude: newMarker?.lng,
      });
    }
  }

  changeTown() {
    const container = document.getElementById('newMarkers');
    const markerOrigin = document.getElementById('origin') as HTMLElement;
    const markerDestination = document.getElementById('destination') as HTMLElement;
    const nodeOrigin = markerOrigin.cloneNode(true) as HTMLElement;
    const nodeDestination = markerDestination.cloneNode(true) as HTMLElement;
    nodeOrigin.classList.add('hidden');
    nodeDestination.classList.add('hidden');
    container?.appendChild(nodeOrigin);
    container?.appendChild(nodeDestination);
    this.mapUtilsService.addCustomMarkers(this.map, undefined, true);
  };

  public async filter(event: { selectedClients: IBase[], selectedTowns: IBase[], selectedTypes: ExpeditionType[], selectedStatus: ExpeditionStatus[], cancelled: boolean }) {
    this.searching = true;
    this.bookings = initialBookingsState;
    this.bookings.value = [];
    this.clientsSelected = event.selectedClients;
    this.townsSelected = event.selectedTowns;
    this.typesSelected = event.selectedTypes;
    this.statusSelected = event.selectedStatus;
    this.cancelledStatusSelected = event.cancelled;
    this.bookings.pagination.actualPage = 0;
    await this.getBookings();
    this.searching = false;
  };

  async search(event: any) {
    this.searching = true;
    this.bookings = initialBookingsState;
    this.bookings.value = [];
    this.searchValue = event;
    this.bookings.pagination.actualPage = 0;
    await this.getBookings();
    this.searching = false;
  };

  editBooking() {
    this.isFullMap = true;
    this.edit = true;
  };

  closeDetails() {
    this.bookingSelected = undefined;
    this.newBooking = false;
  };

  bookingCreated(multiple: boolean) {
    if (this.bookingFromFavourite) {
      this.router.navigate(['/users/' + this.bookingFromFavouriteUserId], { state: { fromFavouriteBooking: true } });
    } else {
      this.goToBookings();
    }
    this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.createBooking', NotificationTypes.SUCCESS, `title${multiple ? 'Multiple' : ''}`, `text${multiple ? 'Multiple' : ''}`);
  }

  async deleteBookingAction(comment: string) {
    this.deletingBooking = true;
    await this.bookingsService.deleteBooking(this.bookingSelected.id, comment).then(() => {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.deleteBooking', NotificationTypes.SUCCESS, 'successTitle', 'successText');
    }, () => {
      if (this.bookingSelected.tripStatus === ExpeditionStatus.READY) {
        this.notificationService.title = this.literalService.get(
          `errorOccurred`,
          true,
        )
        this.notificationService.message = this.literalService.get(
          `bookings.actions.deleteBooking.errorText`,
          true,
        )
        this.notificationService.show(NotificationTypes.DANGER);
      }
    });
    this.deleteBookingModal.toggle();
    this.closeDetails();
    this.getBookings();
    this.deletingBooking = false;
  };

  async markAsAFault() {
    const missed = this.bookingSelected.missed;
    this.bookingSelected = await this.bookingsService.updateMissed(this.bookingSelected.id);
    if (missed) {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.unMarkAsAFault', NotificationTypes.SUCCESS);
    } else {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.markAsAFault', NotificationTypes.SUCCESS);
    }
    this.getBookings();
  };

  goToUser(userId: number, userType: UserType): void {
    this.appComponent.setRouteName('users');
    this.router.navigate([`/users${userType === UserType.Driver ? `/${userType.toLowerCase()}` : ''}/${userId}`]);
  }

  goToTrip(tripId: number): void {
    this.appComponent.setRouteName('trips');
    this.router.navigate(['/trips/' + tripId]);
  }

  showMaxReservationTimeModal() {
    this.maxReservationTimeModal.toggle();
  };
  
  showExceedingKmLimitModal(availabilitiesSelected: any) {
    this.availabilitiesToBook = availabilitiesSelected;
    this.maxKmsPerMonth = availabilitiesSelected[0].serviceInfo.routes[0].maxKmsPerMonth || 0;
    this.distanceTravelled = this.newBookingObject.user.distanceTravelled || 0;
    this.exceedingKmLimitModal.toggle();
  };
  
  showBlockedUserModal(element: any) {
    this.penaltyEndDate = new Date(element.penalty.penaltyEndDate);
    this.penaltyReason = element.penalty.penaltyReason;
    this.newBookingObject = element.booking;
    if (!this.blockedUserModal.isVisible()) this.blockedUserModal.toggle();
  };

  back() {
    this.newBookingObject = {} as IBookingCreate;
    this.blockedUserModal.toggle();
  };

  availableDestinations(destinationsStops: any, stop: any) {
    const isSelectable = destinationsStops && destinationsStops.some((dbs: any) => dbs.id === stop.id);
    return isSelectable;
  };

  async selectStop(event: any) {
    const destinationsStops = event.destinationsStops || [];
    const bounds = new google.maps.LatLngBounds();
    let stops: any[] = [];
    this.serviceInfos.forEach((serviceInfo: IServiceInfo) => {
      serviceInfo.stops.forEach((stop: any) => {
        document.getElementById('stop' + serviceInfo.id + stop.id)?.remove();
        let stopMarker: HTMLElement;
        const isSelectable = this.availableDestinations(destinationsStops, stop);
        if (isSelectable) {
          stopMarker = document.getElementById('stopMarker') as HTMLElement;
        } else {
          stopMarker = document.getElementById('stopMarkerDisabled') as HTMLElement;
        }
        const newMarker = stopMarker.cloneNode(true) as HTMLElement;
        newMarker.setAttribute('id', 'stop' + serviceInfo.id + stop.id);
        newMarker.classList.remove('invisible');
        const exists = stops.find((s: any) => s.id === stop.id);
        if (!exists) {
          stops.push({
            id: stop.id,
            name: stop.name,
            marker: newMarker,
            lat: stop.location.geometry.coordinates[1],
            lng: stop.location.geometry.coordinates[0],
          });
        }
      });
    });
    stops.forEach((stop: any) => {
      bounds.union(
        new google.maps.LatLngBounds(
          new google.maps.LatLng(
            stop.lat,
            stop.lng,
          ),
        ),
      );
    });
    event.showMarkers && this.mapUtilsService.addClustersMarkers(this.map, stops);
    const padding = { top: 50, bottom: 50, left: 50, right: 50 };
    this.map.fitBounds(bounds, padding);
  };
 
  async createBooking(newBooking: IBookingCreate) {
    this.creatingBooking = true;
    const newBookings = this.makeBookingArray(newBooking, this.availabilitiesToBook);
    await this.bookingsService.multipleBooking(newBookings).then(() => {
      this.bookingCreated(newBooking.multipleDates);
      this.creatingBooking = false;
    }, (error) => {
      console.log("ERROR", error);
      this.creatingBooking = false;
    });
  };

  makeBookingArray(newBooking: IBookingCreate, availabilities: any) {
    const newBookings: IBookingCreate[] = [] as IBookingCreate[];
    availabilities.forEach((availability: any) => {
      let booking = {...newBooking};
      booking.availabilityId = availability.availabilityId;
      booking.originStopId = availability.inStop.id!;
      booking.originStop = {
        id: availability.inStop.id,
        name: availability.inStop.name,
        latitude: availability.inStop.latitude,
        longitude: availability.inStop.longitude
      };
      booking.destinationStops.forEach((destination: any) => {
        destination.exitStop = {
          id: availability.outStop.id,
          name: availability.outStop.name,
          latitude: availability.outStop.latitude,
          longitude: availability.outStop.longitude
        };
      });
      booking.destinationId = availability.outStop.id!;
      booking.serviceAvailabilityResponseId = availability.serviceAvailabilityResponseId;
      booking.serviceId = availability.serviceId;
      booking.tripId = availability.expeditionId;
      newBookings.push(booking);
    });
    return newBookings;
  }

  async createFavourite(favouriteName: string) {
    this.creatingFavourite = true;
    await this.favouritesService.createFavourite(favouriteName, this.bookingSelectedForFavourite!).then(() => {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.createFavourite.success', NotificationTypes.SUCCESS);
    }, (error: any) => {
      console.log(error);
      this.creatingFavourite = false;
    });
    this.createFavouriteModal.toggle();
    this.bookingSelectedForFavourite = undefined;
    this.creatingFavourite = false;
  };

  async bookWithFavourite(favourite: IFavourites) {
    this.viewFavourites.toggle();
    const newBooking = { ...this.newBookingObject };
    this.newBookingObject = {} as IBookingCreate;
    this.newBookingObject.targetUserId = newBooking.targetUserId;
    this.newBookingObject.townId = favourite.town!.id!;
    this.newBookingObject.originStopId = favourite.inStop!.id!;
    this.newBookingObject.date = moment().format('YYYY-MM-DD');
    this.newBookingObject.time = favourite.time!;
    this.newBookingObject.favourite = favourite;
    this.changeDetectorRef.detectChanges();
  };

  async openFavourite(userId: number) {
    this.favourites = initialFavouritesState;
    this.favouriteUserId = userId;
    const favourites = await this.favouritesService.getFavourites(userId, 1, 7);
    this.favourites = favourites;
    this.viewFavourites.toggle();
    this.bookingFromFavourite = true;
  };

  async onFavouriteChangePage(event: any) {
    const { isNext, specificPage } = event;
    if (!specificPage) {
      this.favourites.pagination.actualPage = isNext
        ? this.favourites.pagination.actualPage + 1
        : this.favourites.pagination.actualPage - 1;
    } else {
      this.favourites.pagination.actualPage = specificPage;
    }
    this.favourites = await this.favouritesService.getFavourites(this.favouriteUserId, this.favourites.pagination.actualPage, this.favourites.pagination.size);
  }

  async showNotifiedModal(event: Event, bookingId: number, cancelled: boolean = false) {
    this.bookingSelectedForNotified = await this.bookingsService.getBooking(bookingId, cancelled) as IBookingDetails;
    this.notifiedBookingModal.toggle();
    event.stopPropagation();
  };

  async modifyNotified(isNotified: boolean) {
    await this.bookingsService.updateNotified(this.bookingSelectedForNotified.id!, isNotified).then((res: any) => {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.notifiedBooking', NotificationTypes.SUCCESS, 'notificationTitle', isNotified ? `notificationNotNotified` : `notificationNotified`);
      const index = this.bookings.value.findIndex((booking: IBooking) => this.bookingSelectedForNotified.id === booking.id);
      this.bookings.value[index].notified = res.isNotified;
    });
    this.notifiedBookingModal.toggle();
  };

  getTooltipText(type: BookingOrigin, notified?: boolean, userPhone?: string) {
    return this.bookingsService.getChannelTooltipText(type, notified, userPhone);
  };

  async deleteFavourite(favouriteId: number) {
    this.viewFavourites.toggle();
    this.favouritesService.deleteFavourite(favouriteId).then(() => {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.viewFavourite.delete', NotificationTypes.SUCCESS, 'successTitle', 'successText');
    }, () => {
      this.utilsService.showNotification(images.sidebar.bookings, 'bookings.actions.viewFavourite.delete', NotificationTypes.DANGER, 'errorTitle', 'errorText');
    });
  }

  async rebook(booking: IBooking) {
    this.rebookBooking = true;

    let queryParams: any = {
      'bookingId': booking.id
    }
    if (booking.cancelled) queryParams = {
      ...queryParams,
      'cancelled': booking.cancelled
    }
    
    this.router.navigate(
      ['/bookings/new'],
      {
        queryParams: queryParams
      }
    );
  }

  async updateTicketing() {
    const { id, paidDateTime, validatedDateTime } = this.bookingSelected as IBookingDetails;
    this.bookingSelected = await this.bookingsService.updatePaidDateTime(id!, !!paidDateTime);
    this.bookingSelected = await this.bookingsService.updateValidatedDateTime(id!, !!validatedDateTime);
    this.notificationService.image = this.images.booking.ticket.ticket;
    this.notificationService.title = this.literalService.get(
      `bookings.actions.ticketingStatus.notification.title`,
      true,
    );
    const statusKey = `bookings.actions.ticketingStatus.notification.${
      this.bookingSelected.paidDateTime ? 'paidText' : 'notPaidText'
    }And${
      this.bookingSelected.validatedDateTime ? 'ValidatedText' : 'NotValidatedText'
    }`;
    const ticketResultStatus = this.literalService.get(statusKey);
    this.notificationService.message = this.literalService.get(
      `bookings.actions.ticketingStatus.notification.ticketMarked`,
      true,
    ) + ticketResultStatus;
    this.notificationService.show(NotificationTypes.SUCCESS);
    // this.ticketingStatusModal.toggle();
  }

  closeExceedingKm() {
    this.newBookingObject = {} as IBookingCreate;
    this.newBookingObject.destinationStops = [];
    this.newBookingObject.stops = [];
    this.newBookingObject.date = moment().format('YYYY-MM-DD');
    this.newBookingObject.time = moment().format('HH:mm');
    this.closeExceedingKmHandler = true;
  }

  ngOnDestroy(): void { 
    clearInterval(this.interval);
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
